import React, { useState } from "react";
import MyInvests from "./invests/MyInvests";
import Myevents from "./events/Myevents";
import MyPartnership from "./partnership/MyPartnership";
import MyRaces from "./races/MyRaces";
import MyFileds from "./fileds/MyFileds";
import TabJoin from "../../../../components/pages/TabOfJoins";
import MyTrips from "./trips/MyTrips";
import MyJoinAuctions from "./Auctions/MyJoinAuctions";

export default function MyJoins() {
  const [tab, setTab] = useState(1);
  const tabs = [
    "استثمارتي",
    "الفعاليات",
    "التحالفات",
    "السباقات",
    "الساحات",
    "الرحلات",
    "المزادات",
  ];
  return (
    <div>
      <section className=" my-2 flex items-center justify-start gap-1 md:gap-2 overflow-auto w-full ">
        {tabs.map((e, inx) => (
          <TabJoin
            val={inx + 1}
            key={inx}
            text={e}
            tab={tab}
            setTabState={setTab}
          />
        ))}
      </section>
      <div>{tab === 1 && <MyInvests />}</div>
      <div>{tab === 2 && <Myevents />}</div>
      <div>{tab === 3 && <MyPartnership />}</div>
      <div>{tab === 4 && <MyRaces />}</div>
      <div>{tab === 5 && <MyFileds />}</div>
      <div>{tab === 6 && <MyTrips />}</div>
      <div>{tab === 7 && <MyJoinAuctions />}</div>
    </div>
  );
}
