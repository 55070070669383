import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { load } from "../../../../utils/isLoadingStore";
import Loader from "../../../../components/Loader/Loader";
import ImageCropper from "../../../../components/pages/cropper/ImageCropper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FileUploded from "../../../../components/pages/FileUploded";
import { useNavigate } from "react-router";
import usePostFormData from "../../../../utils/usePostForm";
import { isLogged } from "../../../../utils/inStore";
import { Link } from "react-router-dom";
const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(file);
  });
};

const Input = ({ text, value, onChange, name, errors, type, span = false }) => (
  <section className={`${span ? "lg:col-span-2" : "col-span-1"}`}>
    <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
      {text}
    </h1>
    <input
      name={name}
      value={value}
      onChange={onChange}
      className=" inpt "
      type={type}
      placeholder={text}
      required
    />
    {<p className="error">{errors[name]}</p>}
  </section>
);
let citites = [
  "الرياض",
  "جدة",
  "الدمام",
  "المدينة المنورة",
  "مكة المكرمة",
  "الطائف",
  "اخرى",
];

export default function AddAuctions({ close }) {
  const [form, setForm] = useState({
    camel_name: "",
    camel_age: "",
    camel_color: "",
    camel_breed: "",
    camel_type: "",
    city: "",
    address: "",
    map_link: "",
    status: "upcoming",
    start_time: "",
    end_time: "",
    starting_price: "",
    bid_increment: "",
    insurance_amount: "",
    reserve_price: "",
    details: "",
    auction_card_image: "",
    auction_images: [],
    auction_files: [],
  });

  const [croppedImage, setCroppedImage] = useState(null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setForm({ ...form, card_image: imageDataUrl });
    }
  };
  const onCropComplete = (croppedBlob) => {
    const croppedFile = new File([croppedBlob], "cropped-image.jpg", {
      type: "image/jpeg",
    });
    setCroppedImage(croppedFile);
    setForm({ ...form, card_image: null }); // Close cropper after cropping
  };

  const [img, setImg] = useState(null);
  const handleFileChangeImg = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setImg(imageDataUrl);
    }
  };

  const onImgComplete = (croppedBlob) => {
    const croppedFile = new File([croppedBlob], "details.jpg", {
      type: "image/jpeg",
    });
    setForm({ ...form, auction_images: [...form.auction_images, croppedFile] });
    setImg(null);
  };

  const handelChanege = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handelChanegeDetails = (value) => {
    setForm({ ...form, details: value });
  };
  const [errors, setErrors] = useState({});

  const validate = () => {
    let formErrors = {};

    // Validate camel name
    if (!form.camel_name) {
      formErrors.camel_name = "الرجاء إدخال اسم الجمل";
    }

    // Validate camel age
    if (!form.camel_age) {
      formErrors.camel_age = "الرجاء إدخال عمر الجمل";
    } else if (isNaN(form.camel_age) || form.camel_age <= 0) {
      formErrors.camel_age = "يجب أن يكون عمر الجمل رقمًا موجبًا";
    }

    // Validate camel color
    if (!form.camel_color) {
      formErrors.camel_color = "الرجاء إدخال لون الجمل";
    }

    // Validate camel breed
    if (!form.camel_breed) {
      formErrors.camel_breed = "الرجاء إدخال سلالة الجمل";
    }

    // Validate camel type
    if (!form.camel_type) {
      formErrors.camel_type = "الرجاء إدخال نوع الجمل";
    }

    // Validate city
    if (!form.city) {
      formErrors.city = "الرجاء إدخال المدينة";
    }

    // Validate address
    if (!form.address) {
      formErrors.address = "الرجاء إدخال العنوان";
    }

    // // Validate map link
    // if (!form.map_link) {
    //   formErrors.map_link = "الرجاء إدخال رابط الخريطة";
    // }

    const today = new Date();

    // Extract date part only for comparison
    const todayDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59
    );
    const startTime = form.start_time ? new Date(form.start_time) : null;
    const endTime = form.end_time ? new Date(form.end_time) : null;
    // Validate start time
    if (!form.start_time) {
      formErrors.start_time = "الرجاء إدخال وقت البدء";
    } else if (startTime <= todayDate) {
      formErrors.start_time = "يجب أن يكون وقت البدء بعد اليوم";
    }

    // Validate end time
    if (!form.end_time) {
      formErrors.end_time = "الرجاء إدخال وقت الانتهاء";
    } else if (endTime < todayDate) {
      formErrors.end_time = "يجب أن يكون وقت الانتهاء اليوم أو بعده";
    } else if (endTime <= startTime) {
      formErrors.end_time = "يجب أن يكون وقت الانتهاء بعد وقت البدء";
    }

    // Validate starting price
    if (!form.starting_price) {
      formErrors.starting_price = "الرجاء إدخال السعر المبدئي";
    } else if (isNaN(form.starting_price) || form.starting_price <= 0) {
      formErrors.starting_price = "يجب أن يكون السعر المبدئي رقمًا موجبًا";
    }

    // Validate bid increment
    if (!form.bid_increment) {
      formErrors.bid_increment = "الرجاء إدخال زيادة المزاد";
    } else if (isNaN(form.bid_increment) || form.bid_increment <= 0) {
      formErrors.bid_increment = "يجب أن تكون زيادة المزاد رقمًا موجبًا";
    }

    // Validate insurance amount
    if (!form.insurance_amount) {
      formErrors.insurance_amount = "الرجاء إدخال مبلغ التأمين";
    } else if (isNaN(form.insurance_amount) || form.insurance_amount <= 0) {
      formErrors.insurance_amount = "يجب أن يكون مبلغ التأمين رقمًا موجبًا";
    }

    // // Validate details
    // if (!form.details) {
    //   formErrors.details = "الرجاء إدخال تفاصيل المزاد";
    // }

    // Validate auction card image
    if (!croppedImage) {
      formErrors.auction_card_image = "الرجاء رفع الصورة المصغرة للمزاد";
    }

    // // Validate auction images
    // if (!form.auction_images || form.auction_images.length === 0) {
    //   formErrors.auction_images = "الرجاء رفع صور المزاد";
    // }

    // // Validate auction files
    // if (!form.auction_files || form.auction_files.length === 0) {
    //   formErrors.auction_files = "الرجاء رفع ملفات المزاد";
    // }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };

  const navigate = useNavigate();
  let today = new Date().toISOString().slice(0, 10);

  const links = {
    "camel-owner": "camelOwner/auctions/create",
    squer: "field-owner/auctions/create",
    super_admin: "superAdmin/auctions/create",
    provider: "serviceProvider/auctions/create",
  };
  const { role } = useContext(isLogged);
  const { postFormData, error, response } = usePostFormData(links[role]);
  const handelAdd = () => {
    if (validate()) {
      const formdata = new FormData();
      formdata.append("camel_name", form.camel_name);
      formdata.append("camel_age", form.camel_age);
      formdata.append("camel_color", form.camel_color);
      formdata.append("camel_breed", form.camel_breed);
      formdata.append("camel_type", form.camel_type);
      formdata.append("city", form.city);
      formdata.append("address", form.address);
      formdata.append("map_link", form.map_link);
      formdata.append("status", form.status);
      formdata.append(
        "start_time",
        form.status === "upcoming"
          ? `${form.start_time} 00:00:00`
          : `${today} 00:00:00`
      );
      formdata.append("end_time", `${form.end_time} 23:59:00`);
      formdata.append("starting_price", form.starting_price);
      formdata.append("bid_increment", form.bid_increment);
      formdata.append("insurance_amount", form.insurance_amount);
      if (form.reserve_price) {
        formdata.append("reserve_price", form.reserve_price);
      }
      formdata.append("details", form.details);
      formdata.append("auction_card_image", croppedImage);
      form.auction_images.forEach((i) =>
        formdata.append("auction_images[]", i)
      );
      form.auction_files.forEach((i) => formdata.append("auction_files[]", i));

      postFormData(formdata);
    }
  };

  console.log(error);

  const roles = {
    "camel-owner": "camel_owner",
    squer: "field_owner",
    provider: "service_provider",
    vistor: "visitor",
    super_admin: "super_admin",
  };
  useEffect(() => {
    if (!!response) {
      navigate(
        `/auction-details?auction=${response.data.id}&role=${roles[role]}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">اضافة مزاد</h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          {/* <form className=" grid grid-cols-1   items-center gap-8 md:gap-x-16 lg:gap-x-32   "> */}
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الصورة المصغرة
            </h1>
            {croppedImage && (
              <div className="relative">
                <button
                  onClick={() => setCroppedImage(null)}
                  className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <img
                  alt="new cover"
                  className="max-h-[230px] rounded-[20px] max-w-full mx-auto"
                  // src={URL.createObjectURL(file)}
                  src={URL.createObjectURL(croppedImage)}
                />
              </div>
            )}
            {!croppedImage && !form.card_image && (
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  // onChange={(e) => {
                  //   setForm({ ...form, card_image: e.target.files[0] });
                  // }}
                  onChange={handleFileChange}
                />
              </section>
            )}
            {form.card_image && (
              <ImageCropper
                imageSrc={form.card_image}
                onCropComplete={onCropComplete}
                w={390}
                h={250}
              />
            )}

            {<p className="error">{errors.card_image}</p>}
          </section>
          {/*
           */}{" "}
          <Input
            span={true}
            value={form.camel_name}
            name={"camel_name"}
            onChange={handelChanege}
            errors={errors}
            text="اسم الإبل / الجمل"
          />
          <Input
            value={form.camel_age}
            name={"camel_age"}
            type="number"
            onChange={handelChanege}
            errors={errors}
            text="العمر"
          />
          <Input
            value={form.camel_color}
            name={"camel_color"}
            onChange={handelChanege}
            errors={errors}
            text="اللون"
          />
          <Input
            value={form.camel_breed}
            name={"camel_breed"}
            onChange={handelChanege}
            errors={errors}
            text="السلالة"
          />
          <Input
            value={form.camel_type}
            name={"camel_type"}
            onChange={handelChanege}
            errors={errors}
            text="النوع"
          />
          <section className={`col-span-1`}>
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              المدينة
            </h1>
            <select
              name="city"
              value={form.city}
              onChange={handelChanege}
              className=" inpt "
            >
              <option hidden>المدينة</option>
              {citites.map((i, inx) => (
                <option key={inx} value={i}>
                  {i}
                </option>
              ))}
            </select>

            {<p className="error">{errors.city}</p>}
          </section>
          <Input
            value={form.address}
            name={"address"}
            onChange={handelChanege}
            errors={errors}
            text="العنوان"
          />
          <Input
            span={true}
            value={form.map_link}
            name={"map_link"}
            onChange={handelChanege}
            errors={errors}
            text="رابط خرائط جوجل للعنوان"
          />
          {/* <section className={`lg:col-span-2`}>
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              حالة المزاد
            </h1>
            <select
              name="status"
              value={form.status}
              onChange={handelChanege}
              className=" inpt "
            >
              <option hidden>حالة المزاد</option>

              <option value={"upcoming"}>قادم</option>
              <option value={"ongoing"}>جاري</option>
            </select>

            {<p className="error">{errors.status}</p>}
          </section> */}
          <section className={`col-span-1`}>
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تاريخ البدء
            </h1>
            <input
              type="date"
              name="start_time"
              value={form.start_time}
              onChange={handelChanege}
              className=" disabled:opacity-70 inpt "
              disabled={form.status === "ongoing"}
            />
            {<p className="error">{errors.start_time}</p>}
          </section>
          <Input
            type="date"
            value={form.end_time}
            name={"end_time"}
            onChange={handelChanege}
            errors={errors}
            text="تاريخ الانتهاء "
          />
          <Input
            type="number"
            value={form.starting_price}
            name={"starting_price"}
            onChange={handelChanege}
            errors={errors}
            text="السعر الافتتاحي بالريال"
          />
          <Input
            type="number"
            value={form.bid_increment}
            name={"bid_increment"}
            onChange={handelChanege}
            errors={errors}
            text=" قيمة المزايدة بالريال"
          />
          <Input
            type="number"
            value={form.insurance_amount}
            name={"insurance_amount"}
            onChange={handelChanege}
            errors={errors}
            text="مبلغ التأمين / رسوم الدفع"
          />
          <Input
            type="number"
            value={form.reserve_price}
            name={"reserve_price"}
            onChange={handelChanege}
            errors={errors}
            text="اقصي سعر / الذي يتم انهاء المزاد عنده"
          />
          <section className=" min-h-[300px] col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تفاصيل المزاد
            </h1>
            <ReactQuill
              value={form.details}
              onChange={handelChanegeDetails}
              className="text-editor  h-[200px]  rtl"
              style={{ direction: "rtl", textAlign: "right" }}
            />
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              ملفات التعريفية
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.auction_files.map((e, inx) => (
                <>
                  <FileUploded
                    key={inx}
                    da={e}
                    del={() => {
                      let da = form.auction_files;
                      da = da.filter((i, index) => inx !== index);
                      setForm({
                        ...form,
                        auction_files: da,
                      });
                    }}
                  />
                </>
              ))}

              <label className="btn2" htmlFor="files">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع ملف</h1>
                </section>
              </label>
              <input
                type="file"
                id="files"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      auction_files: [...form.auction_files, e.target.files[0]],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              صور التفاصيل
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.auction_images.map((e, inx) => (
                <>
                  <FileUploded
                    key={inx}
                    da={e}
                    del={() => {
                      let da = form.auction_images;
                      da = da.filter((i, index) => inx !== index);
                      setForm({
                        ...form,
                        auction_images: da,
                      });
                    }}
                  />
                </>
              ))}

              {!img && (
                <label className="btn2" htmlFor="img">
                  <section className="">
                    <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                    <h1 className="text-[#f2f2f2]">رفع ملف</h1>
                  </section>
                </label>
              )}
              <input
                type="file"
                id="img"
                // onChange={(e) => {
                //   if (!!e.target.files[0]) {
                //     setForm({
                //       ...form,
                //       auction_files: [...form.auction_files, e.target.files[0]],
                //     });
                //   }
                // }}
                onChange={handleFileChangeImg}
                style={{ display: "none" }}
              />
            </section>
            {img && (
              <ImageCropper
                imageSrc={img}
                onCropComplete={onImgComplete}
                w={1000}
                h={450}
              />
            )}
          </section>
        </form>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handelAdd} className="btn9">
            اضافة
          </button>

          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {Object.keys(errors).length !== 0 && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            بعض البيانات الاساسية ناقصة
          </section>
        )}
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response ? (
              <>
                {!!error.response.data ? (
                  <>
                    {!!error.response.data.error ? (
                      <>
                        {error.response.data.error ===
                        "ليس لديك اشتراك نشط." ? (
                          <section className=" flex items-center gap-2 flex-wrap ">
                            {error.response.data.error}
                            <Link
                              className=" underline text-[15px] p-2  font-bold "
                              to="/packages"
                            >
                              {" "}
                              عرض الباقات
                            </Link>
                          </section>
                        ) : (
                          error.response.data.error
                        )}
                      </>
                    ) : (
                      "  هناك مشكلة"
                    )}
                  </>
                ) : (
                  "  هناك مشكلة"
                )}
              </>
            ) : (
              "  هناك مشكلة"
            )}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
