import React from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import { Link } from "react-router-dom";
import Pay from "./Pay";

export default function MyWon() {
  const { data, error } = useFetchWithReload("global/auctions/my_auctions/won");

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة{" "}
        </h1>
      </div>
    );
  }
  return (
    <div>
      {data && (
        <>
          {data.winning_auctions ? (
            <>
              <table>
                <thead>
                  <tr>
                    <th>اسم المزاد</th>
                    <th>عرض المزاد</th>
                    <th>اعلي عرض</th>
                    <th>حالة الدفع</th>
                  </tr>
                </thead>
                <tbody>
                  {data.winning_auctions.map((e, inx) => (
                    <tr key={inx}>
                      <td className=" text-center ">{e.camel_name}</td>
                      <td className=" text-center ">
                        <Link
                          className=" text-sky-800 underline font-bold "
                          to={`/auction-details?auction=${e.auction_id}`}
                        >
                          عرض{" "}
                        </Link>
                      </td>
                      <td className=" text-center ">{e.highest_bid}</td>
                      <td className=" text-center ">
                        {e.final_payment_status === "unpaid" ? (
                          <Pay id={e.auction_id} />
                        ) : (
                          "تم الدفع"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <h1 className=" text-red-600 text-xl font-bold text-center ">
                لا توجد مزادات تم ربحها
              </h1>
            </>
          )}
        </>
      )}
    </div>
  );
}
