import React, { useContext } from "react";
import { isLogged } from "../../../../utils/inStore";
import Error from "../../../../components/Error/Error";
import { useLocation } from "react-router";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import { Link } from "react-router-dom";

export default function ShowMail() {
  const { logged, role } = useContext(isLogged);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("mail");
  let link = `superAdmin/contact/show/${id}`;
  const { data, error } = useFetchWithReload(link);
  const roles = {
    camel_owner: "مالك ابل",
    field_owner: "صاحب ساحة",
    service_provider: "مقدم خدمة",
    visitor: "زائر",
    super_admin: "ادارة",
  };

  if (role !== "super_admin" || !logged || error) {
    return <Error />;
  }
  return (
    <div>
      {data && (
        <div className="  p-8 ">
          <Link
            className=" absolute top-4 right-4 underline "
            to="/contact-dash"
          >
            الرجوع للبريد
          </Link>
          <h1 className=" text-[#432316] my-8 text-2xl font-bold text-center ">
            تفاصيل البريد
          </h1>
          <section className=" bg-[#E8DCC0] rounded-[15px] p-4 mx-auto max-w-[1100px] grid grid-cols-1 lg:grid-cols-2 gap-8 md:px-12 lg:px-32  ">
            <section>
              <h4>المرسل :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.name}
              </h1>
            </section>
            <section>
              <h4>الجنسية :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.nationality}
              </h1>
            </section>
            <section>
              <h4>البريد الاكتروني :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.email}
              </h1>
            </section>
            <section>
              <h4> رقم الهاتف :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.phone}
              </h1>
            </section>
            <section>
              <h4> تاريخ الارسال :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.created_at.substring(0, 10)}
              </h1>
            </section>
            <section>
              {/* <h4> نوع المرسل :</h4> */}
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {roles[data.role]}
              </h1>
            </section>
            <section className=" lg:col-span-2 ">
              <h4> الموضوع :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.subject}
              </h1>
            </section>
            <section className=" lg:col-span-2 ">
              <h4> الرسالة :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.message}
              </h1>
            </section>
          </section>
        </div>
      )}
    </div>
  );
}
