import React, { useState } from "react";

export default function Filter({
  setCheckedCities,
  filter,
  checkedcities,
  setCheckedState,
}) {
  const [checked, setChecked] = useState(filter);
  const isChecked = (val) => checked.includes(val);

  const handleClick = (val) => {
    const updatedChecked = isChecked(val)
      ? checked.filter((item) => item !== val)
      : [...checked, val];

    setChecked(updatedChecked);
    setCheckedState(updatedChecked);
  };

  const checkOptions = [
    { name: "مزادات القادمة", val: "upcoming" },
    { name: "مزادات القائمة", val: "ongoing" },
    { name: "مزادات المنتهية", val: "ended" },
  ];

  const [checkedCities, setCheckedCitie] = useState(checkedcities);
  const isCheckedCities = (val) => checkedCities.includes(val);

  const handleClickCities = (val) => {
    const updatedChecked = isCheckedCities(val)
      ? checkedCities.filter((item) => item !== val)
      : [...checkedCities, val];

    setCheckedCitie(updatedChecked);
    setCheckedCities(updatedChecked);
  };
  const handleClickCitiesAll = () => {
    setCheckedCitie([
      "الرياض",
      "جدة",
      "الدمام",
      "المدينة المنورة",
      "مكة المكرمة",
      "الطائف",
      "اخرى",
    ]);
    setCheckedCities([
      "الرياض",
      "جدة",
      "الدمام",
      "المدينة المنورة",
      "مكة المكرمة",
      "الطائف",
      "اخرى",
    ]);
  };
  const checkOptionsCities = [
    { name: "الرياض", val: "الرياض" },
    { name: "جدة", val: "جدة" },
    { name: "الدمام", val: "الدمام" },
    { name: "المدينة المنورة", val: "المدينة المنورة" },
    { name: "مكة المكرمة", val: "مكة المكرمة" },
    { name: "الطائف", val: "الطائف" },
    { name: "اخرى", val: "اخرى" },
  ];

  return (
    <div className="pb-4 border text-[#333333] border-[#43231580] rounded-[16px]">
      <h1 className="px-3 py-2 text-[20px] font-[700] border-b border-[#432315]">
        فلتر
      </h1>
      <section className="px-3 py-5 border-b text-[#4F4F4F] border-[#432315]">
        {checkOptions.map((option, index) => (
          <section key={index} className="flex items-center gap-3">
            <input
              type="checkbox"
              id={`customCheckbox-${index}`}
              className="custom-checkbox"
              checked={isChecked(option.val)}
              onChange={() => handleClick(option.val)}
            />
            <label
              htmlFor={`customCheckbox-${index}`}
              className="text-[20px] font-[500]"
            >
              {option.name}
            </label>
          </section>
        ))}
      </section>
      <section className="px-3 py-5 text-[#4F4F4F] border-[#432315]">
        <section className="flex items-center gap-3">
          <input
            type="checkbox"
            id={`all`}
            className="custom-checkbox"
            checked={checkedcities.length === 7}
            onChange={handleClickCitiesAll}
          />
          <label htmlFor={`all`} className="text-[20px] font-[500]">
            الكل
          </label>
        </section>
        {checkOptionsCities.map((option, index) => (
          <section key={index} className="flex items-center gap-3">
            <input
              type="checkbox"
              id={`city-${index}`}
              className="custom-checkbox"
              checked={isCheckedCities(option.val)}
              onChange={() => handleClickCities(option.val)}
            />
            <label htmlFor={`city-${index}`} className="text-[20px] font-[500]">
              {option.name}
            </label>
          </section>
        ))}
      </section>
    </div>
  );
}
