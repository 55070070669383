import "./App.css";
import Layout from "./components/Layout/Layout";
import "./utils/all.min.css";
import "./components/Assests.css";
import { Route, Routes } from "react-router";
import Home from "./Pages/Home/Home";
import OwnerProfile from "./Pages/Owner/OwnerProfile";
import AllCamelsOfOwner from "./Pages/Owner/AllCamels";
import AllProductsOfOwner from "./Pages/Owner/AllProductsOfOwner";
import AllEventsOfProduct from "./Pages/Owner/AllEventsOfProduct";
import Servaces from "./Pages/Servces/Servaces";
import Events from "./Pages/Events/Events";
import EventDetails from "./Pages/Events/EventDetails";
import JoinEvent from "./Pages/Events/JoinEvent";
import Investment from "./Pages/investments/Investment";
import Invest from "./Pages/investments/invest/Invest";
import JoinInvest from "./Pages/investments/Join/JoinInvest";
import Alliance from "./Pages/alliance/Alliance";
import PartnerShip from "./Pages/alliance/PartnerShip/PartnerShip";
import JoinParnerShip from "./Pages/alliance/Join/Join";
import Squers from "./Pages/squres/Squers";
import SquerDetails from "./Pages/squres/Squer/SquerDetails";
import JoinSquer from "./Pages/squres/JoinSquer/JoinSquer";
import Tourism from "./Pages/Turism/Tourism";
import TripDetails from "./Pages/Turism/details/TripDetails";
import BookAtrip from "./Pages/Turism/join/BookAtrip";
import Stores from "./Pages/stores/Stores";
import StoreOwner from "./Pages/stores/Owner/StoreOwner";
import ProductDeatils from "./Pages/stores/Details/ProductDeatils";
import Cart from "./Pages/cart/Cart";
import Veterinary from "./Pages/veterinary/Veterinary";
import Hospitals from "./Pages/veterinary/Hospitals/Hospitals";
import Hospital from "./Pages/veterinary/Hospitals/hospitla/Hospital";
import Consaltent from "./Pages/veterinary/consaltent/Consaltent";
import ConstDetails from "./Pages/veterinary/consaltent/deatails/ConstDetails";
import Auctions from "./Pages/auctions/Auctions";
import Blogs from "./Pages/Blogs/Blogs";
import BlogDeatails from "./Pages/Blogs/blog/BlogDeatails";
// import Races from "./Pages/Races/Races";
import Allraces from "./Pages/Races/allraces/Allraces";
import RaceDeatails from "./Pages/Races/race/RaceDeatails";
import JoinRace from "./Pages/Races/Join/JoinRace";
import Experts from "./Pages/Experts/Experts";
import ExpertDetails from "./Pages/Experts/Deatails/ExpertDetails";
import Media from "./Pages/media/Media";
import NewsPage from "./Pages/news/NewsPage";
import AllNews from "./Pages/news/allNews/AllNews";
import NewDetails from "./Pages/news/deatails/NewDetails";
import Communication from "./Pages/communication/Communication";
import Profile from "./Pages/Profile/Profile";
import Settings from "./Pages/settings/Settings";
import Dashboard from "./Pages/dashboard/Dashboard";
import Notifications from "./Pages/notification/Notifications";
import Login from "./Pages/auth/login/Login";
import { useContext, useEffect } from "react";
import { load } from "./utils/isLoadingStore";
import Loader from "./components/Loader/Loader";
import Error from "./components/Error/Error";
import ALlOwners from "./Pages/Home/ALlOwners";
import PackageDetails from "./Pages/dashboard/components/packages/PackageDetails";
import MyPackages from "./Pages/dashboard/components/packages/myPackage/MyPackages";
import PackageDetailsSuper from "./Pages/dashboard/SuperAdmin/Pages/packages/PackageDetails";
import PendingFiled from "./Pages/dashboard/SuperAdmin/Pages/Filed/PendingFiled";
import PendingRequest from "./Pages/dashboard/SuperAdmin/Pending/PendingRequest";
import PendingAccount from "./Pages/dashboard/SuperAdmin/Pending/Pages/Service/PendingAccount";
import Calback from "./Pages/pay/Calback";
import ContactSuperDash from "./Pages/dashboard/SuperAdmin/contact/Contact";
import ShowMail from "./Pages/dashboard/SuperAdmin/contact/ShowMail";
import Register from "./Pages/auth/login/Register";
import Policies from "./Pages/auth/login/Policies";
import Order from "./Pages/stores/Details/Order/Order";
import ScrollToTop from "./utils/ScrollToTop";
import AllCamels from "./Pages/Home/AllCamels";
// import Notification from "./notification/Notification";
import { onMessage } from "firebase/messaging";
import { messaging } from "./notification/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PackagesPage from "./Pages/Packages/PackagesPage";
import { HelmetProvider } from "react-helmet-async";
import MyJoinsPage from "./Pages/Packages/Joins";
import JoinsInvests from "./Pages/Packages/JoinsInvests";
import JoinEvnets from "./Pages/Packages/JoinEvnets";
import JoinsPartners from "./Pages/Packages/JoinsPartners";
import JoinsRaces from "./Pages/Packages/JoinsRaces";
import JoinsFileds from "./Pages/Packages/JoinsFileds";
import JoinsTrips from "./Pages/Packages/JoinsTrips";
import RelatedSitesPage from "./Pages/Home/RelatedSites/RelatedSitesPage";
import AuctionDetails from "./Pages/auctions/detIails/AuctionDetails";
import JoinsAuctions from "./Pages/Packages/JoinsAuctions";
import Innovations from "./Pages/Home/Innovations";
import ShowInnovation from "./Pages/dashboard/SuperAdmin/contact/ShowInnovation";

function App() {
  const { isLoading } = useContext(load);
  // const [notification, setNotification] = useState(null);
  useEffect(() => {
    // Listen for foreground notifications
    onMessage(messaging, (payload) => {
      // console.log("Message received. ", payload);
      const { title } = payload.notification;

      toast.info(title);
    });
  }, []);
  return (
    <div className=" rtl almarai-regular break-words">
      {isLoading && <Loader />}
      <ToastContainer
        theme="colored"
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        rtl={true}
      />
      <HelmetProvider>
        <Layout>
          <ScrollToTop />
          <Routes>
            <Route path="*" element={<Error />} />
            {/* Auth */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/policies" element={<Policies />} />
            {/* Home */}
            <Route path="/" element={<Home />} />
            <Route path="/all-camel-owners" element={<ALlOwners />} />
            <Route path="/all-camel-cards" element={<AllCamels />} />
            {/* Owner Profile */}
            <Route path="/owner" element={<OwnerProfile />} />
            <Route path="/camels-of-owner" element={<AllCamelsOfOwner />} />
            <Route path="/products-of-owner" element={<AllProductsOfOwner />} />
            <Route path="/events-of-owner" element={<AllEventsOfProduct />} />
            {/* Services */}
            <Route path="/services" element={<Servaces />} />
            {/* Events */}
            <Route path="/events" element={<Events />} />
            <Route path="/event-details" element={<EventDetails />} />
            <Route path="/join-event" element={<JoinEvent />} />
            {/* invset */}
            <Route path="/invset" element={<Investment />} />
            <Route path="/investment-details" element={<Invest />} />
            <Route path="/join-invest" element={<JoinInvest />} />
            {/* alliance */}
            <Route path="/alliance" element={<Alliance />} />
            <Route path="/partnership-details" element={<PartnerShip />} />
            <Route path="/join-parnership" element={<JoinParnerShip />} />
            {/* Arena */}
            <Route path="/squres" element={<Squers />} />
            <Route path="/squre-details" element={<SquerDetails />} />
            <Route path="/join-squer" element={<JoinSquer />} />
            {/* Tourism */}
            <Route path="/tourism" element={<Tourism />} />
            <Route path="/trip-details" element={<TripDetails />} />
            <Route path="/book-trip" element={<BookAtrip />} />
            {/* stores */}
            <Route path="/stores" element={<Stores />} />
            <Route path="/store-owner" element={<StoreOwner />} />
            <Route path="/product-details" element={<ProductDeatils />} />
            <Route path="/order" element={<Order />} />
            {/* Cart */}
            <Route path="/shop" element={<Cart />} />
            {/* veterinary */}
            <Route path="/veterinary" element={<Veterinary />} />
            <Route path="/veterinary-hospitals" element={<Hospitals />} />
            <Route path="/hospital-details" element={<Hospital />} />
            <Route path="/veterinary-consultatives" element={<Consaltent />} />
            <Route path="/consultative-details" element={<ConstDetails />} />
            {/* auctions */}
            <Route path="/auctions" element={<Auctions />} />
            <Route path="/auction-details" element={<AuctionDetails />} />
            {/* <Route path="/join-auction" element={<JoinAuctions />} /> */}
            {/* Blog */}
            <Route path="/blog" element={<Blogs />} />
            <Route path="/blog-details" element={<BlogDeatails />} />
            {/* Races */}
            <Route path="/races" element={<Allraces />} />
            {/* <Route path="/all-races" element={<Allraces />} /> */}
            <Route path="/race-details" element={<RaceDeatails />} />
            <Route path="/join-race" element={<JoinRace />} />
            {/* Experts */}
            <Route path="/experts" element={<Experts />} />
            <Route path="/expert-details" element={<ExpertDetails />} />
            {/* Media */}
            <Route path="/media" element={<Media />} />
            {/* News */}
            <Route path="/news" element={<NewsPage />} />
            <Route path="/all-news" element={<AllNews />} />
            <Route path="/news-deatils" element={<NewDetails />} />
            {/* communication */}
            <Route path="/communication" element={<Communication />} />

            {/* Dashboards */}
            {/* Profile */}
            <Route path="/profile" element={<Profile />} />
            {/* settings */}
            <Route path="/settings" element={<Settings />} />
            {/* dashboard */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/pending" element={<PendingRequest />} />
            <Route path="/contact-dash" element={<ContactSuperDash />} />
            <Route path="/show-mail" element={<ShowMail />} />
            {/* Notification */}
            <Route path="/notification" element={<Notifications />} />
            {/* <Route path="/notificationtest" element={<Notification />} /> */}
            {/* Package */}
            <Route path="/package-details" element={<PackageDetails />} />
            <Route path="/my-package" element={<MyPackages />} />
            <Route path="/package" element={<PackageDetailsSuper />} />
            {/* fileds */}
            <Route path="/pendingFields" element={<PendingFiled />} />
            <Route path="/pending-accounts" element={<PendingAccount />} />

            {/* Pay */}
            <Route path="/callback" element={<Calback />} />
            {/* PAcakages */}
            <Route path="/packages" element={<PackagesPage />} />
            <Route path="/my-subscription" element={<MyJoinsPage />} />
            <Route path="/my-subscription-invests" element={<JoinsInvests />} />
            <Route path="/my-subscription-events" element={<JoinEvnets />} />
            <Route
              path="/my-subscription-partners"
              element={<JoinsPartners />}
            />
            <Route path="/my-subscription-races" element={<JoinsRaces />} />
            <Route path="/my-subscription-fields" element={<JoinsFileds />} />
            <Route path="/my-subscription-trips" element={<JoinsTrips />} />
            <Route path="/my-subscription-auctions" element={<JoinsAuctions />} />
            

            {/*  */}
            <Route path="/related-platforms" element={<RelatedSitesPage />} />
            <Route path="/send-innovation" element={<Innovations />} />
            <Route path="/show-innovation" element={<ShowInnovation />} />
          </Routes>
        </Layout>
      </HelmetProvider>
    </div>
  );
}

export default App;
