import React from "react";

export default function Joiners({ data }) {
  return (
    <div className=" p-2 md:p-6 " >
      <table>
        <thead>
          <tr>
            <th>الترتيب</th>
            <th>الاسم</th>
            <th>قيمة المزايدة</th>
            <th>الوقت / التاريخ</th>
          </tr>
        </thead>
        <tbody>
          {data.map((i, inx) => (
            <tr key={inx}>
              <td className=" text-center ">{i.rank}</td>
              <td className=" text-center ">{i.name}</td>
              <td className=" text-center ">{i.amount} ر.س</td>
              <td className=" text-center ">{i.bid_time}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
