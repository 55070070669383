import React, { useContext, useEffect, useState } from "react";
import { isLogged } from "../../../../utils/inStore";
import Pref from "./Pref";
import AddAuctions from "./AddAuctions";
import Auctions from "./Auctions";
import DeleteActions from "./Delete";
import Attends from "./Attends";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import Edit from "./Edit";

export default function AuctionsDash() {
  const [mainFilter, setMainFilter] = useState({
    search: "",
    card: true,
  });
  const handleMainFilterChange = (e) => {
    setMainFilter({ ...mainFilter, search: e.target.value });
  };
  const { role } = useContext(isLogged);
  const links = {
    "camel-owner": "camelOwner/auctions/myAuctions",
    squer: "field-owner/auctions/myAuctions",
    super_admin: "superAdmin/auctions/myAuctions",
    provider: "serviceProvider/auctions/myAuctions",
  };
  const [reload, setReload] = useState(false);
  const re = () => {
    setReload(!reload);
  };
  const { data, error } = useFetchWithReload(links[role], reload);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let filtered = [];
    if (!!data) {
      filtered = data.data;
      if (mainFilter.search) {
        filtered = filtered.filter((item) =>
          item.camel_name.includes(mainFilter.search)
        );
      }
    }

    setFilteredData(filtered);
  }, [mainFilter, data]);

  const [deleteId, setDeletId] = useState();
  const [editId, setEditId] = useState();
  const [detailsId, setDetailsId] = useState();
  const [openAdd, setOpenAdd] = useState(false);
  const [closed, setClosed] = useState(false);

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <div className="flex items-center justify-between flex-wrap gap-y-3 md:gap-2">
        {/* Search */}

        <section className="w-full max-w-[650px] flex items-center gap-6 justify-between">
          <div className="flex items-center bg-[#E8DCC0] text-[#A59382] flex-1 rounded-[10px] border border-[#E8DCC0]">
            <i className="fa-solid fa-magnifying-glass m-2"></i>
            <input
              type="text"
              value={mainFilter.search}
              name="search"
              onChange={handleMainFilterChange}
              placeholder="أبحث هنا..."
              className="outline-none bg-[black]/0 flex-1 p-2"
            />
          </div>
        </section>

        <button
          onClick={() => setOpenAdd(true)}
          className=" btn flex items-center gap-2 "
        >
          <i class="fa-solid fa-circle-plus"></i>
          اضافة مزاد
        </button>
      </div>
      <div className="flex items-start flex-wrap gap-4 my-10 ">
        <section className="flex-1">
          <>
            {detailsId ? (
              <>
                <Attends
                  id={detailsId}
                  closed={closed}
                  re={re}
                  back={() => setDetailsId()}
                  setClosed={setClosed}
                />
              </>
            ) : (
              <>
                <Auctions
                  setDetailsId={setDetailsId}
                  setDeleteId={setDeletId}
                  setEditId={setEditId}
                  setClosed={setClosed}
                  data={[...filteredData].reverse()}
                />
                {filteredData.length === 0 && (
                  <h1 className=" text-center my-4 text-xl ">لا يوجد مزاد</h1>
                )}
              </>
            )}
          </>
        </section>
        <section className=" w-full max-w-[390px] md:max-w-[230px] ">
          {!!data && <Pref data={data.data} />}
        </section>
      </div>

      {openAdd && <AddAuctions close={setOpenAdd} />}
      {deleteId && (
        <DeleteActions set={setFilteredData} id={deleteId} close={setDeletId} />
      )}
      {editId && (
        <Edit set={setFilteredData} id={editId} close={setEditId} />
      )}
    </div>
  );
}
