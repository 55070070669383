import React, { useEffect, useState } from "react";
import Hr from "../../../components/pages/Hr";
import RelatedComponent from "./RelatedComponent";
import useFetchWithReload from "../../../utils/useGetWithReload";

export default function RelatedSitesPage() {
  const [saudis, setSaudi] = useState([]);
  const [other, setOther] = useState([]);
  const { data } = useFetchWithReload(`global/related_sites`);

  useEffect(() => {
    if (data) {
      let sa = data.filter((i) => i.type === "saudi");
      let oth = data.filter((i) => i.type === "other");
      setSaudi(sa);
      setOther(oth);
    }
  }, [data]);
  return (
    <div className=" p-8 md:p-12  ">
      <h1 className=" text-center text-[24px] lg:text-[32px] font-[600] text-[#290900] ">
        مواقع ذات صلة
      </h1>
      {/* <h1 className=" mt-2 text-[18px] font-[400] text-[#A59382] text-center ">
        منصة الإبل هي المرجع الاساسي ل سهولة ما يدور في بالك اي شئ تخص الإبل
      </h1> */}
      <section className=" p-4  text-center ">
        <h1 className=" text-[#432315] text-[36px] font-[600] my-2 ">
          منصات سعودية
        </h1>
        <Hr color="#432315" />
        <section className=" my-6 flex items-start justify-center gap-8 flex-wrap ">
          {saudis.map((i, inx) => (
            <RelatedComponent key={inx} data={i} />
          ))}
        </section>
        {saudis.length === 0 && (
          <h1 className=" my-5 text-center text-[#432315] text-[19px]">
            {data && "   لا توجد منصات"}
          </h1>
        )}
      </section>
      <section className=" p-4  text-center ">
        <h1 className=" text-[#432315] text-[36px] font-[600] my-2 ">
          منصات اخرى
        </h1>
        <Hr color="#432315" />{" "}
        <section className=" my-6 flex items-start justify-center gap-8 flex-wrap ">
          {other.map((i, inx) => (
            <RelatedComponent key={inx} data={i} />
          ))}
        </section>
        {other.length === 0 && (
          <h1 className=" my-5 text-center text-[#432315] text-[19px]">
            {data && "   لا توجد منصات"}
          </h1>
        )}
      </section>
    </div>
  );
}
