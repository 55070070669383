import React, { useState } from "react";
import { useLocation } from "react-router";
import Tab from "../../../components/pages/Tab";
// import PackagesDahBoardSuper from "./Pages/packages/PackagesDahBoardSuper";
import StoreSuperAdminDash from "./Pages/store/StoreSuperAdminDash";
import ClincsSuperAdminDashboard from "./Pages/Clincs/ClincsSuperAdminDashboard";
import FieldDashSuper from "./Pages/Filed/FieldDashSuper";
import PartnerShipDashSuperAdmin from "./Pages/PartnerShip/PartnerShipDashSuperAdmin";
import RacesDahSuperAdmin from "./Pages/Races/RacesDahSuperAdmin";
import InvestDashSuper from "./Pages/Invet/InvestDashSuper";
import MediaDashSuper from "./Pages/meia/MediaDashSuper";
import TorismDashSuper from "./Pages/tourism/TorismDashSuper";
import EventsDashSuperAdmin from "./Pages/Events/EventsDashSuperAdmin";
import MainPackageDash from "./Pages/packages/MainDash/MainPackageDash";
import BlogsDashBoard from "../pages/Blogs/BlogsDashBoard";
import NewsDash from "../pages/news/NewsDash";
import CamelCardsDash from "./Pages/CamelCard/CamelCardsDash";
import UsersDashboard from "./Pages/users/UsersDashboard";
import Bocket from "./Pages/users/Bocket/Bocket";
import SuperAdminAuctionDash from "../pages/Auction/SuperAdminAuctionDash";

export default function SuperAdminDashBoard() {
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type") || 1;
  const [tab, setTab] = useState(+type);

  return (
    <div className=" py-12 px-6 md:p-12 ">
      <h1 className=" text-[36px] font-[800] text-[#432315] ">لوحة التحكم</h1>
      {/* Tabs */}
      <section className=" my-2 flex items-center justify-start gap-1 md:gap-2 overflow-auto lg:flex-wrap w-full ">
        <Tab val={1} text={"الباقات"} tab={tab} setTabState={setTab} />
        <Tab val={14} text={"المستخدمين"} tab={tab} setTabState={setTab} />
        <Tab val={15} text={"المحفظة"} tab={tab} setTabState={setTab} />
        <Tab val={16} text={"المزاد"} tab={tab} setTabState={setTab} />
        <Tab val={2} text={"المتجر"} tab={tab} setTabState={setTab} />
        <Tab val={3} text={"العيادات"} tab={tab} setTabState={setTab} />
        <Tab val={4} text={"الساحات"} tab={tab} setTabState={setTab} />
        <Tab val={5} text={"التحالفات"} tab={tab} setTabState={setTab} />
        <Tab val={6} text={"السباقات"} tab={tab} setTabState={setTab} />
        <Tab val={7} text={"الاستثمار"} tab={tab} setTabState={setTab} />
        <Tab val={8} text={"الوسائط"} tab={tab} setTabState={setTab} />
        <Tab val={9} text={"السياحة"} tab={tab} setTabState={setTab} />
        <Tab val={10} text={"الفعاليات"} tab={tab} setTabState={setTab} />
        <Tab val={11} text={"المدونة"} tab={tab} setTabState={setTab} />
        <Tab val={12} text={"الاخبار"} tab={tab} setTabState={setTab} />
        <Tab val={13} text={"كروت الابل"} tab={tab} setTabState={setTab} />
      </section>
      <div>
        {tab === 1 && <MainPackageDash />}
        {tab === 2 && <StoreSuperAdminDash />}
        {tab === 3 && <ClincsSuperAdminDashboard />}
        {tab === 4 && <FieldDashSuper />}
        {tab === 5 && <PartnerShipDashSuperAdmin />}
        {tab === 6 && <RacesDahSuperAdmin />}
        {tab === 7 && <InvestDashSuper />}
        {tab === 8 && <MediaDashSuper />}
        {tab === 9 && <TorismDashSuper />}
        {tab === 10 && <EventsDashSuperAdmin />}
        {tab === 11 && <BlogsDashBoard />}
        {tab === 12 && <NewsDash />}
        {tab === 13 && <CamelCardsDash />}
        {tab === 14 && <UsersDashboard />}
        {tab === 15 && <Bocket />}
        {tab === 16 && <SuperAdminAuctionDash />}
      </div>
    </div>
  );
}
