import React, { useState } from "react";
import Joins from "./Joins";
import MyWon from "./MyWon";

export default function MyJoinAuctions() {
  const [tab, setTab] = useState(1);
  return (
    <div>
      <section className=" mb-6 flex flex-wrap gap-3  ">
        <button
          onClick={() => setTab(1)}
          className={` ${tab === 1 ? "btn" : "btnn"} `}
        >
          المزادات المشارك بها
        </button>
        <button
          onClick={() => setTab(2)}
          className={` ${tab === 2 ? "btn" : "btnn"} `}
        >
          المزادات التي ربحتها
        </button>
      </section>

      {tab === 1 && <Joins />}
      {tab === 2 && <MyWon />}
    </div>
  );
}
