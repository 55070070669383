import React, { useEffect } from "react";
import usePostWithToken from "../../../../../utils/usePostJson";

export default function Pay({ id }) {
  const { postData, response } = usePostWithToken(
    `global/auctions/${id}/pay_final_amount`
  );
  const handelJoin = () => {
    postData({
      callback_url: `${process.env.REACT_APP_CAll_BACK}?redirect=auction-details&type=auction&value=${id}`,
    });
  };
  useEffect(() => {
    if (response) {
      window.location.href = response.redirect_url;
    }
  }, [response]);
  return (
    <div>
      <button onClick={handelJoin} className=" btn ">
        دفع
      </button>
    </div>
  );
}
