import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Time = ({ val, name }) => (
  <section className=" h-[55px] w-[60px] rounded-[8px] border-[#432315] border border-b-[3px] ">
    <h1 className=" text-center text-[21px] font-[700] leading-[18px] mt-2  ">
      {val}
    </h1>
    <h4 className=" text-center text-[14px] font-[400] leading-[18px] ">
      {name}
    </h4>
  </section>
);

export default function AuctionsComponent({ data }) {
  const titles = {
    upcoming: "قادم",
    ongoing: "جاري",
    ended: "منتهي",
  };
  const targetDate = new Date(data.end_time).getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference <= 0 || data.status === "ended") {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=" text-[#432315] w-full max-w-[330px] rounded-[16px] border border-[#7F7F7F] p-2 ">
      <div className=" relative bg-origin-content bg-cover rounded-[16px]  w-full h-[180px]">
        <img
          className={`  absolute left-0 top-0 h-full w-full rounded-[16px] object-cover `}
          src={data.media.auction_card_image[0].url}
          alt="cover"
        />
        <section className=" absolute top-0 right-0 bg-[#432315] text-white py-[2px] px-3 rounded-bl-[16px] rounded-tr-[16px] ">
          {data.status === "upcoming"
            ? `يفتتح يوم  ${data.start_time.substring(0, 10)}`
            : titles[data.status]}
        </section>
      </div>
      <h1 className=" my-2 text-[22px] font-[800] ">{data.camel_name}</h1>
      <section className=" flex gap-[6px] items-end ">
        <h1 className=" my-2 text-[22px] font-[800] ">
          {data.starting_price} ر.س
        </h1>
        <h4 className=" text-[15px] text-[#A59382] font-[600] ">
          السعر الافتتاحي
        </h4>
      </section>
      <section className="my-3 px-3 text-[#290900] text-[16px] font-[600] flex items-center gap-1 justify-between ">
        <h1>العمر : {data.camel_age} سنة</h1>
        <h1>اللون : {data.camel_color} </h1>
      </section>
      <section className="my-3 px-3 text-[#290900] text-[16px] font-[600] flex items-center gap-1 justify-between ">
        <h1>السلالة : {data.camel_breed}</h1>
        <h1>النوع : {data.camel_type} </h1>
      </section>
      <section className=" flex items-center gap-2 text-[16px] text-[#290900] font-[700] ">
        <i className="fa-solid fa-location-dot"></i>
        {data.city !== "اخرى" && `${data.city}  , `} {data.address}
      </section>
      <section className=" my-4 flex items-center justify-center gap-4 ">
        <Time val={timeLeft.days} name={"يوم"} />
        <Time val={timeLeft.hours} name={"ساعة"} />
        <Time val={timeLeft.minutes} name={"دقيقة"} />
        <Time val={timeLeft.seconds} name={"ثواني"} />
      </section>
      <section className=" text-center mt-4 ">
        <Link
          to={`/auction-details?auction=${data.id}&role=${data.owner_type}`}
        >
          <button className=" btn2 ">تفاصيل المزاد</button>
        </Link>
      </section>
    </div>
  );
}
