import React from "react";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import ExportReports from "./ExportReports";
import { Link } from "react-router-dom";

export default function Reports() {
  const { data, error } = useFetchWithReload(`superAdmin/auctions/report`);
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }

  const statues = {
    ongoing: "جاري",
    ended: "منتهي",
    upcoming: "قادي",
  };

  return (
    <div>
      <h1 className=" my-4 text-center text-xl font-bold ">تقارير المزادات</h1>
      {data && (
        <>
          {data.data && data.data.length > 0 ? (
            <>
              <ExportReports data={data.data} />
              <table>
                <thead>
                  <tr>
                    <th>اسم المزاد</th>
                    <th>المدينة</th>
                    <th>حالة المزاد</th>
                    <th>السعر الافتتاحي</th>
                    <th>سعر التأمين</th>
                    <th>اعلي عرض</th>
                    <th>عدد المشاركين</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.map((i, inx) => (
                    <tr key={inx}>
                      <td className=" text-center ">
                        <Link
                          to={`/auction-details?auction=${i.id}&role=${i.owner_type}`}
                          className=" underline "
                        >
                          {i.camel_name}{" "}
                        </Link>
                      </td>
                      <td className=" text-center ">{i.city}</td>
                      <td className=" text-center ">{statues[i.status]}</td>
                      <td className=" text-center ">{i.starting_price}</td>
                      <td className=" text-center ">{i.insurance_amount}</td>
                      <td className=" text-center ">{i.highest_bid}</td>
                      <td className=" text-center ">{i.bidders_count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <h1 className=" text-red-600 text-xl font-bold text-center ">
                لا توجد مزادات
              </h1>
            </>
          )}
        </>
      )}
    </div>
  );
}
