import React from "react";
import AuctionDashComponent from "./AuctionDashComponent";

export default function Auctions({
  data,
  setDeleteId,
  setDetailsId,
  setEditId,
  setClosed,
}) {
  return (
    <div className=" flex items-satrt justify-center gap-3 flex-wrap ">
      {data.map((e) => (
        <AuctionDashComponent
          setDetailsId={setDetailsId}
          setDeleteId={setDeleteId}
          setEditId={setEditId}
          setClosed={setClosed}
          key={e.id}
          data={e}
        />
      ))}
    </div>
  );
}
