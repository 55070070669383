import React, { useEffect } from "react";
import usePostWithToken from "../../../utils/usePostJson";

export default function Bid({ invest, bid, re, max, start, status }) {
  const { postData, response } = usePostWithToken(
    `global/auctions/${invest}/bid`
  );
  const handelJoin = () => {
    postData({
      action: "increment",
    });
  };

  useEffect(() => {
    if (response) {
      re();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  return (
    <div>
      {status === "ongoing" && (
        <>
          <h1 className=" text-[#290900] text-center font-[600] text-[20px] leading-[38px] ">
            زيادة علي العرض
          </h1>
          {max.length > 0 ? (
            <h1 className=" text-[#523a33] text-center font-[600] text-[17px] leading-[38px] ">
              اضافة {bid} ر.س علي أعلي عرض
            </h1>
          ) : (
            <h1 className=" text-[#523a33] text-center font-[600] text-[17px] leading-[38px] ">
              بداية العرض بالسعر الافتتاحي {start} ر.س
            </h1>
          )}
          <button onClick={handelJoin} className="btn2">
            تأكيد العرض
          </button>
        </>
      )}
      {status === "upcoming" && (
        <>
          <h1 className=" text-[#290900] text-center font-[600] text-[20px] leading-[38px] ">
            انتظر حتي يتم فتح المزاد لتقديم العرض
          </h1>
        </>
      )}
    </div>
  );
}
