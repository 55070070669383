import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import usePostWithToken from "../../../../../utils/usePostJson";
import { load } from "../../../../../utils/isLoadingStore";
import Loader from "../../../../../components/Loader/Loader";
import useFetchWithReload from "../../../../../utils/useGetWithReload";

export default function AddPackage({ close, re }) {
  const [form, setForm] = useState({
    name: "",
    description: "",
    duration: "",
    duration_type: "",
    price: "",
    discount_percentage: 0,
    platform_fee_percentage: "",
    user_type: "",
    features: [],
  });
  const handleChangeAgenda = (e, inx) => {
    const { name, value } = e.target;
    const updatedAgenda = form.features.map((item, index) =>
      index === inx ? { ...item, [name]: value } : item
    );

    setForm((prevState) => ({
      ...prevState,
      features: updatedAgenda,
    }));
  };

  const handelChanege = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const [features, setFeatures] = useState([]);
  const { data } = useFetchWithReload("superAdmin/features/all");
  useEffect(() => {
    if (!!data) {
      setFeatures(data.features);
    }
  }, [data]);

  const isEmployeeIncluded = (id) =>
    form.features.some((employee) => Number(employee.id) === Number(id));

  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};

    // Validate name
    if (!form.name) {
      formErrors.name = "الرجاء إدخال اسم الباقة";
    }

    // Validate description
    if (!form.description) {
      formErrors.description = "الرجاء إدخال وصف الباقة";
    }

    // Validate duration
    if (!form.duration || !form.duration_type) {
      formErrors.duration = "الرجاء إدخال مدة الباقة";
    }

    // // Validate duration type
    // if (!form.duration_type) {
    //   formErrors.duration_type = "الرجاء اختيار نوع المدة";
    // }

    // Validate price
    if (!form.price) {
      formErrors.price = "الرجاء إدخال سعر الباقة";
    }

    // Validate discount percentage
    // if (!form.discount_percentage) {
    //   formErrors.discount_percentage = "الرجاء إدخال نسبة الخصم او اجعلها 0";
    // }

    // Validate platform fee percentage
    if (!form.platform_fee_percentage) {
      formErrors.platform_fee_percentage = "الرجاء إدخال نسبة المنصة";
    }

    // Validate user type
    if (!form.user_type) {
      formErrors.user_type = "الرجاء اختيار نوع المستخدم";
    }

    // Validate features (should be an array)
    if (!form.features || form.features.length === 0) {
      formErrors.features = "الرجاء إضافة مميزات الباقة";
    }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };

  let link = "superAdmin/packages/create";
  const { postData, response, error } = usePostWithToken(link);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      //   await postFormData(formdata);
      //   postData(form);
      postData({
        name: form.name,
        description: form.description,
        duration: form.duration,
        duration_type: form.duration_type,
        price: form.price,
        discount_percentage: !!form.discount_percentage
          ? form.discount_percentage
          : 0,
        platform_fee_percentage: form.platform_fee_percentage,
        user_type: form.user_type,
        features: form.features,
      });
      //   console.log(form);
    }
  };
  useEffect(() => {
    if (!!response) {
      re();
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">اضافة باقة</h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اسم الباقة
            </h1>
            <input
              name="name"
              value={form.name}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="اسم الباقة"
              required
            />
            {<p className="error">{errors.name}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نوع مستخدم الباقة
            </h1>
            <select
              name="user_type"
              value={form.user_type}
              onChange={handelChanege}
              className=" inpt "
            >
              <option hidden>نوع المستخدم</option>
              <option value={"visitor"}>زائر</option>
              <option value={"service_provider"}>مزود خدمة</option>
              <option value={"camel_owner"}>مالك ابل</option>
              <option value={"field_owner"}>مالك ساحة</option>
            </select>
            {<p className="error">{errors.user_type}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نبذة عن الباقة
            </h1>
            <textarea
              name="description"
              cols={2}
              value={form.description}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" نبذة عن الباقة "
              required
            />
            {<p className="error">{errors.description}</p>}
          </section>
          <section className=" col-span-1  ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              مدة الباقة
            </h1>
            <section className="flex">
              <input
                name="duration"
                value={form.duration}
                onChange={handelChanege}
                className=" inpt "
                type="number"
                min={1}
                placeholder="مدة الباقة"
              />
              <select
                name="duration_type"
                value={form.duration_type}
                onChange={handelChanege}
                className="inpt"
              >
                <option hidden>نوع المدة</option>
                <option value="days">يوم</option>
                <option value="months">شهر</option>
                <option value="years">سنة</option>
              </select>
            </section>
            {<p className="error">{errors.duration}</p>}
          </section>
          <section className=" col-span-1  ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              سعر الباقة
            </h1>
            <input
              name="price"
              value={form.price}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              min={1}
              placeholder="سعر الباقة"
            />
            {<p className="error">{errors.price}</p>}
          </section>
          <section className=" col-span-1  ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نسبة الخصم (اذا وجد)
            </h1>
            <input
              name="discount_percentage"
              value={form.discount_percentage}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              min={0}
              max={100}
              placeholder="نسبة الخصم  %"
            />
            {<p className="error">{errors.discount_percentage}</p>}
          </section>
          <section className=" col-span-1  ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نسبة المنصة
            </h1>
            <input
              name="platform_fee_percentage"
              value={form.platform_fee_percentage}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              min={1}
              placeholder="نسبة المنصة"
            />
            {<p className="error">{errors.platform_fee_percentage}</p>}
          </section>
          <section className=" col-span-1 text-xl font-bold lg:col-span-2 text-center ">
            مميزات الباقة
          </section>
        </form>
        {form.features.map((e, inx) => (
          <div
            key={inx}
            className="  border-b col-span-1 lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 items-center gap-8 md:gap-x-16 lg:gap-x-32 "
          >
            <section className=" col-span-1 lg:col-span-2 flex items-center justify-between p-2 ">
              <h1 className=" h-[30px] w-[30px] flex items-center justify-center text-lg rounded-full text-[#fafafa] bg-[#432315] ">
                {inx + 1}
              </h1>
              <button
                onClick={() => {
                  let newAgenda = form.features.filter(
                    (e, index) => inx !== index
                  );
                  setForm({ ...form, features: newAgenda });
                }}
              >
                <i className="fa-solid fa-trash text-red-600"></i>
              </button>
            </section>
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                الميزة
              </h1>
              <select
                name="id"
                value={e.id}
                onChange={(e) => handleChangeAgenda(e, inx)}
                className=" inpt "
              >
                <option hidden>الميزة</option>
                {features.map((e, inx) => (
                  <option
                    hidden={isEmployeeIncluded(e.id)}
                    key={inx}
                    value={e.id}
                  >
                    {e.name}
                  </option>
                ))}
              </select>
            </section>
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                حدود الميزة
              </h1>
              <input
                name="usage_limit"
                value={e.usage_limit}
                onChange={(e) => handleChangeAgenda(e, inx)}
                className=" inpt "
                type="number"
                min={0}
                placeholder="غير محدود"
                required
              />
            </section>
          </div>
        ))}

        <section className=" col-span-1 lg:col-span-2 text-center ">
          <button
            onClick={() => {
              setForm({
                ...form,
                features: [
                  ...form.features,
                  {
                    id: "",
                    usage_limit: null,
                  },
                ],
              });
            }}
            className="btn2 my-3"
          >
            اضافة ميزة
          </button>
          <br />
          {<p className="error">{errors.features}</p>}
        </section>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handleSubmit} className="btn9">
            اضافة
          </button>
          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response.data.message
              ? error.response.data.message
              : "  هناك مشكلة"}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
