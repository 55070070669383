import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import DropDown from "./DropDown";
import { isLogged } from "../../utils/inStore";
import Menu from "@mui/material/Menu";
import Cookies from "js-cookie";
import { count } from "../../utils/countStore";
// import { load } from "../../utils/isLoadingStore";

export default function Nav() {
  let links = [
    {
      text: "متجر",
      link: "/stores",
    },
    {
      text: "مزاد",
      link: "/auctions",
    },
    {
      text: "ساحات",
      link: "/squres",
    },
  ];
  let serLinks = [
    // {
    //   text: "خدمات حكومية / الكترونية",
    //   link: "/eServices",
    // },
    {
      text: "الطب البيطري",
      link: "/veterinary",
    },
    {
      text: "استثمار",
      link: "/invset",
    },
    {
      text: "تحالفات",
      link: "/alliance",
    },
    {
      text: "خبراء",
      link: "/experts",
    },
  ];
  // let caLinks = [
  //   {
  //     text: "سباقات",
  //     link: "/races",
  //   },
  //   {
  //     text: "متجر",
  //     link: "/stores",
  //   },
  //   {
  //     text: "مزادات",
  //     link: "/auctions",
  //   },
  //   {
  //     text: "خبراء",
  //     link: "/experts",
  //   },
  //   {
  //     text: "الساحات",
  //     link: "/squres",
  //   },
  //   {
  //     text: "سياحة",
  //     link: "/tourism",
  //   },
  // ];
  const events = [
    {
      text: "فعالية",
      link: "/events",
    },
    {
      text: "سباق",
      link: "/races",
    },
    {
      text: "سياحة",
      link: "/tourism",
    },
  ];
  const infos = [
    {
      text: "اخبار",
      link: "/news",
    },
    {
      text: "مدونة",
      link: "/blog",
    },
    {
      text: "وسائط",
      link: "/media",
    },
  ];
  const subs = [
    {
      text: "استثمارتي",
      link: "/my-subscription-invests",
    },
    {
      text: "الفعاليات",
      link: "/my-subscription-events",
    },
    {
      text: "التحالفات",
      link: "/my-subscription-partners",
    },
    {
      text: "السباقات",
      link: "/my-subscription-races",
    },
    {
      text: "الساحات",
      link: "/my-subscription-fields",
    },
    {
      text: "الرحلات",
      link: "/my-subscription-trips",
    },
    {
      text: "المزادات",
      link: "/my-subscription-auctions",
    },
  ];

  function TruncatedParagraph({ text }) {
    // Split the text into an array of words
    const words = text.split(" ");

    // Check if the number of words exceeds maxWords
    const shouldTruncate = words.length > 1;

    // Create the truncated text
    const truncatedText = shouldTruncate ? words.slice(0, 1).join(" ") : text;

    return truncatedText;
  }
  const { logged } = useContext(isLogged);
  const { role } = useContext(isLogged);
  const name = Cookies.get("name");
  const img = Cookies.get("image");
  const roleCookie = Cookies.get("rcom9lsuZsWree9mdXNlcg==");
  let roleofUser = "";
  // let link = "";
  if (roleCookie === "dmlzaXRvcnRvc2VlMDk4") {
    roleofUser = "زائر";
    // link = "visitor/logout";
  }
  if (roleCookie === "aWFtb3duaW5nY2FtZWxzMzA2NA==") {
    roleofUser = "مالك ابل";
    // link = "camelOwner/logout";
  }
  if (roleCookie === "c3F1ZXJvd25lcmhhdmVzb21lZXZlbnRzMjA4") {
    roleofUser = "صاحب ساحة";
    // link = "field-owner/logout";
  }
  if (roleCookie === "SXByb3ZpZHNvbWVzZXJ2aWNlc3lvdWNhbnRlc3RpdDM") {
    roleofUser = "مزود خدمة";
    // link = "logout";
  }
  if (roleCookie === "c3VwZXJBttZG1pbiB0ZXh0IHRvIGNvZGUxxgNjkgdHlwZSAmmyMTM1") {
    roleofUser = "الادارة";
    // link = "logout";
  }

  const data = {
    name: name,
    img: "./imgs/profile/user.jfif",
    type: roleofUser,
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const { setIsLoading } = useContext(load);
  const { setLogged, setRole } = useContext(isLogged);
  const navigation = useNavigate();
  const logOuthandeller = () => {
    // setIsLoading(true);
    // let token = Cookies.get("token");
    // const myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${token}`);

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   redirect: "follow",
    // };
    // fetch(`${process.env.REACT_APP_URL}/api/${link}`, requestOptions).then(
    //   (res) => {
    //     if (res.status === 200 || res.status === 201) {
    //       setIsLoading(false);
    //       setLogged(false);
    //       setRole("");

    //       Cookies.remove("name");
    //       Cookies.remove("token");
    //       Cookies.remove("rcom9lsuZsWree9mdXNlcg==");
    //       navigation("/login");
    //     }
    //   }
    // );
    Cookies.remove("name");
    Cookies.remove("token");
    Cookies.remove("expirein");
    Cookies.remove("value");
    Cookies.remove("image");
    Cookies.remove("type");
    Cookies.remove("rcom9lsuZsWree9mdXNlcg==");
    navigation("/login");
    setLogged(false);
    setRole("");
  };
  const { countNum } = useContext(count);
  return (
    <div className="  flex items-center justify-between bg-[#F5EFDC] border-b border-black p-2 ">
      <section>
        <Link to="/">
          <img
            className="h-[65px]"
            src="./imgs/navLogoCropped.png"
            alt="Logo"
          />
        </Link>
      </section>
      <section className=" navLinks">
        <Link to="/">الرئيسية</Link>
        {/* <DropDown name={"الإبل"} links={caLinks} /> */}
        <DropDown name={"الخدمات"} links={serLinks} />
        <DropDown name={"فعاليات"} links={events} />
        {links.map((li) => (
          <Link key={li.link} to={li.link}>
            {li.text}
          </Link>
        ))}
        <DropDown name={"معلومات"} links={infos} />
        {logged && (
          <>
            {role === "super_admin" ? (
              <Link to="/dashboard?type=1">الباقات</Link>
            ) : (
              <Link to="/packages">الباقات</Link>
            )}
          </>
        )}
        {logged && (
          <>
            {role !== "super_admin" && (
              // <Link to="/my-subscription">مشاراكاتي</Link>
              <DropDown name={"مشاركاتي"} links={subs} />
            )}
          </>
        )}
        <Link to="/communication">تواصل معنا </Link>
        {/* <Link to="/search">
          <i className="fa-solid fa-magnifying-glass "></i>
        </Link> */}
        {logged && (
          <>
            <Link className=" relative " to="/notification">
              <i className="fa-solid fa-bell "></i>
              <span className=" absolute text-[10px] font-normal flex items-center justify-center text-white h-[20px] w-[20px] rounded-full bg-[#876354] bottom-[-4px] left-[-8px] ">
                {" "}
                {countNum}
              </span>
            </Link>
            <Link to="/shop">
              <i className="fa-solid fa-bag-shopping ml-1"></i>
            </Link>
          </>
        )}
      </section>
      <section>
        {!logged ? (
          <Link to="/login">
            <button className="btn">تسجيل الدخول</button>
          </Link>
        ) : (
          <>
            <button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className=" flex items-center gap-2 "
            >
              {!!img && img !== "null" ? (
                <img
                  src={`${process.env.REACT_APP_URL}/${img}`}
                  alt="userImage"
                  className=" w-[40px] h-[40px] rounded-full "
                />
              ) : (
                <section className=" flex items-center justify-center  w-[40px] h-[40px] rounded-full shadow-lg bg-gray-200 ">
                  <i className="fa-solid fa-user text-xl text-[#432315] "></i>
                </section>
              )}

              <section className=" px-2 text-start  ">
                <h1 className=" text-[#432315] font-[600] ">
                  <TruncatedParagraph text={data.name} />
                </h1>
                <h1 className=" text-[12px] text-[#432315BF] font-[500] ">
                  {data.type}
                </h1>
              </section>
              <i class="fa-solid fa-caret-down"></i>
            </button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "#E8DCC0",
                },
              }}
            >
              <section className=" rtl almarai-regular   w-[200px] p-[10px] ">
                {role === "camel-owner" && (
                  <section className=" mb-3 text-center ">
                    <Link
                      onClick={handleClose}
                      className=" text-center w-fit border-[#290900] px-2 py-1 border mx-auto text-[#290900] text-[14px] fot-[400] rounded-full "
                      to="/profile"
                    >
                      عرض الصفحة الشخصية
                    </Link>
                  </section>
                )}
                <Link onClick={handleClose} to="/dashboard">
                  <section className=" border-[#A59382] text-black py-2 text-[15px] font-[600] w-full border-b flex items-center gap-2 ">
                    <i className="fa-solid fa-sliders"></i>
                    <h4>الادارة</h4>
                  </section>
                </Link>

                {role === "super_admin" && (
                  <Link onClick={handleClose} to="/pending">
                    <section className=" border-[#A59382] text-black py-2 text-[15px] font-[600] w-full border-b flex items-center gap-2 ">
                      <i className="fa-solid fa-hourglass-end"></i>
                      <h4>الطلبات المعلقة</h4>
                    </section>
                  </Link>
                )}
                {role === "super_admin" && (
                  <Link onClick={handleClose} to="/contact-dash">
                    <section className=" border-[#A59382] text-black py-2 text-[15px] font-[600] w-full border-b flex items-center gap-2 ">
                      <i className="fa-solid fa-message"></i>
                      <h4>معلومات التواصل</h4>
                    </section>
                  </Link>
                )}
                <Link onClick={handleClose} to="/settings">
                  <section className=" border-[#A59382] text-black py-2 text-[15px] font-[600] w-full border-b flex items-center gap-2 ">
                    <i className="fa-solid fa-gear"></i>
                    <h4 className="  ">الاعدادات و الخصوصية</h4>
                  </section>
                </Link>
                <section className="  text-center">
                  <button
                    onClick={logOuthandeller}
                    className=" pt-4 flex items-center gap-2 justify-center w-full  text-[#C62020] text-[15px] font-[600] "
                  >
                    تسجيل خروج{" "}
                    <i className="fa-solid fa-right-from-bracket rotate-180 "></i>{" "}
                  </button>
                </section>
              </section>
            </Menu>
          </>
        )}
      </section>
    </div>
  );
}
