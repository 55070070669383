import React, { useContext, useEffect, useState } from "react";
import useFetchGlobal from "../../../../utils/useGetGlobal";
import ReactDOM from "react-dom";
import ImageCropper from "../../../../components/pages/cropper/ImageCropper";
import FileUploded from "../../../../components/pages/FileUploded";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../../../components/Loader/Loader";
import { load } from "../../../../utils/isLoadingStore";
import { isLogged } from "../../../../utils/inStore";
import usePostFormData from "../../../../utils/usePostForm";
import { Link, useNavigate } from "react-router-dom";

const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(file);
  });
};
const Input = ({ text, value, onChange, name, errors, type, span = false }) => (
  <section className={`${span ? "lg:col-span-2" : "col-span-1"}  `}>
    <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
      {text}
    </h1>
    <input
      name={name}
      value={value}
      onChange={onChange}
      className=" inpt "
      type={type}
      placeholder={text}
      required
    />
    {<p className="error">{errors[name]}</p>}
  </section>
);
let citites = [
  "الرياض",
  "جدة",
  "الدمام",
  "المدينة المنورة",
  "مكة المكرمة",
  "الطائف",
  "اخرى",
];
export default function Edit({ close, id, set }) {
  const [form, setForm] = useState({
    camel_name: "",
    camel_age: "",
    camel_color: "",
    camel_breed: "",
    camel_type: "",
    city: "",
    address: "",
    map_link: "",
    status: "upcoming",
    start_time: "",
    end_time: "",
    starting_price: "",
    bid_increment: "",
    insurance_amount: "",
    reserve_price: "",
    details: "",
    auction_card_image: "",
    auction_images: [],
    auction_files: [],
    deleted_file_ids: [],
    deleted_images_ids: [],
  });
  const [details, setDetails] = useState("");
  const [files, setFiles] = useState([]);
  const [imgs, setImgs] = useState([]);
  const mappedImgs = imgs.filter(
    (i) => !form.deleted_images_ids.includes(i.id)
  );
  const mappedFiles = files.filter(
    (i) => !form.deleted_file_ids.includes(i.id)
  );
  const [croppedImage, setCroppedImage] = useState(null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setForm({ ...form, card_image: imageDataUrl });
    }
  };
  const onCropComplete = (croppedBlob) => {
    const croppedFile = new File([croppedBlob], "cropped-image.jpg", {
      type: "image/jpeg",
    });
    setCroppedImage(croppedFile);
    setForm({ ...form, card_image: null }); // Close cropper after cropping
  };
  const { data } = useFetchGlobal(`global/auctions/show/${id}`);

  useEffect(() => {
    if (data) {
      setDetails(data.data.details);
      setImgs(data.data.media.auction_images);
      setFiles(data.data.media.auction_files);
      setForm({
        camel_name: data.data.camel_name,
        camel_age: data.data.camel_age,
        camel_color: data.data.camel_color,
        camel_breed: data.data.camel_breed,
        camel_type: data.data.camel_type,
        city: data.data.city,
        address: data.data.address,
        map_link: data.data.map_link,
        status: data.data.status,
        start_time: data.data.start_time.substring(0, 10),
        end_time: data.data.end_time.substring(0, 10),
        starting_price: data.data.starting_price,
        bid_increment: data.data.bid_increment,
        insurance_amount: data.data.insurance_amount,
        reserve_price: data.data.reserve_price,
        details: "",
        auction_card_image: null,
        auction_images: [],
        auction_files: [],
        deleted_file_ids: [],
        deleted_images_ids: [],
      });
    }
  }, [data]);

  const [img, setImg] = useState(null);
  const handleFileChangeImg = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setImg(imageDataUrl);
    }
  };

  const onImgComplete = (croppedBlob) => {
    const croppedFile = new File([croppedBlob], "details.jpg", {
      type: "image/jpeg",
    });
    setForm({ ...form, auction_images: [...form.auction_images, croppedFile] });
    setImg(null);
  };

  const handelChanege = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handelChanegeDetails = (value) => {
    setDetails(value);
  };

  let errors = {};

  const links = {
    "camel-owner": `camelOwner/auctions/update/${id}`,
    squer: `field-owner/auctions/update/${id}`,
    super_admin: `superAdmin/auctions/update/${id}`,
    provider: `serviceProvider/auctions/update/${id}`,
  };

  const { role } = useContext(isLogged);
  const { postFormData, error, response } = usePostFormData(links[role]);

  const handelAdd = () => {
    const formdata = new FormData();
    formdata.append("camel_name", form.camel_name);
    formdata.append("camel_age", form.camel_age);
    formdata.append("camel_color", form.camel_color);
    formdata.append("camel_breed", form.camel_breed);
    formdata.append("camel_type", form.camel_type);
    formdata.append("city", form.city);
    formdata.append("address", form.address);
    formdata.append("map_link", form.map_link);
    formdata.append("status", form.status);
    formdata.append("start_time", `${form.start_time} 00:00:00`);
    formdata.append("end_time", `${form.end_time} 23:59:00`);
    formdata.append("starting_price", form.starting_price);
    formdata.append("bid_increment", form.bid_increment);
    formdata.append("insurance_amount", form.insurance_amount);
    if (form.reserve_price) {
      formdata.append("reserve_price", form.reserve_price);
    }
    formdata.append("details", details);
    if (croppedImage) {
      formdata.append("auction_card_image", croppedImage);
    }
    form.auction_images.forEach((i) => formdata.append("auction_images[]", i));
    form.auction_files.forEach((i) => formdata.append("auction_files[]", i));
    form.deleted_images_ids.forEach((i) =>
      formdata.append("deleted_images_ids[]", i)
    );
    form.deleted_file_ids.forEach((i) =>
      formdata.append("deleted_file_ids[]", i)
    );

    postFormData(formdata);
  };

  const navigate = useNavigate();
  const roles = {
    "camel-owner": "camel_owner",
    squer: "field_owner",
    provider: "service_provider",
    vistor: "visitor",
    super_admin: "super_admin",
  };
  useEffect(() => {
    if (!!response) {
      navigate(
        `/auction-details?auction=${response.data.id}&role=${roles[role]}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">تعديل مزاد</h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تعديل الصورة المصغرة
            </h1>
            {croppedImage && (
              <div className="relative">
                <button
                  onClick={() => setCroppedImage(null)}
                  className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <img
                  alt="new cover"
                  className="max-h-[230px] rounded-[20px] max-w-full mx-auto"
                  // src={URL.createObjectURL(file)}
                  src={URL.createObjectURL(croppedImage)}
                />
              </div>
            )}
            {!croppedImage && !form.card_image && (
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  // onChange={(e) => {
                  //   setForm({ ...form, card_image: e.target.files[0] });
                  // }}
                  onChange={handleFileChange}
                />
              </section>
            )}
            {form.card_image && (
              <ImageCropper
                imageSrc={form.card_image}
                onCropComplete={onCropComplete}
                w={390}
                h={250}
              />
            )}

            {<p className="error">{errors.card_image}</p>}
          </section>
          <Input
            span={true}
            value={form.camel_name}
            name={"camel_name"}
            onChange={handelChanege}
            errors={errors}
            text="اسم الإبل / الجمل"
          />
          <Input
            value={form.camel_age}
            name={"camel_age"}
            type="number"
            onChange={handelChanege}
            errors={errors}
            text="العمر"
          />
          <Input
            value={form.camel_color}
            name={"camel_color"}
            onChange={handelChanege}
            errors={errors}
            text="اللون"
          />
          <Input
            value={form.camel_breed}
            name={"camel_breed"}
            onChange={handelChanege}
            errors={errors}
            text="السلالة"
          />
          <Input
            value={form.camel_type}
            name={"camel_type"}
            onChange={handelChanege}
            errors={errors}
            text="النوع"
          />
          <section className={`col-span-1`}>
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              المدينة
            </h1>
            <select
              name="city"
              value={form.city}
              onChange={handelChanege}
              className=" inpt "
            >
              <option hidden>المدينة</option>
              {citites.map((i, inx) => (
                <option key={inx} value={i}>
                  {i}
                </option>
              ))}
            </select>

            {<p className="error">{errors.city}</p>}
          </section>
          <Input
            value={form.address}
            name={"address"}
            onChange={handelChanege}
            errors={errors}
            text="العنوان"
          />
          <Input
            span={true}
            value={form.map_link}
            name={"map_link"}
            onChange={handelChanege}
            errors={errors}
            text="رابط خرائط جوجل للعنوان"
          />
          {/* <section className={`lg:col-span-2`}>
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              حالة المزاد
            </h1>
            <select
              name="status"
              value={form.status}
              onChange={handelChanege}
              className=" inpt "
            >
              <option hidden>حالة المزاد</option>

              <option value={"upcoming"}>قادم</option>
              <option value={"ongoing"}>جاري</option>
            </select>

            {<p className="error">{errors.status}</p>}
          </section> */}
          <section className={`col-span-1`}>
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تاريخ البدء
            </h1>
            <input
              type="date"
              name="start_time"
              value={form.start_time}
              onChange={handelChanege}
              className=" disabled:opacity-70 inpt "
              disabled={form.status === "ongoing"}
            />
            {<p className="error">{errors.start_time}</p>}
          </section>
          <Input
            type="date"
            value={form.end_time}
            name={"end_time"}
            onChange={handelChanege}
            errors={errors}
            text="تاريخ الانتهاء "
          />
          <Input
            type="number"
            value={form.starting_price}
            name={"starting_price"}
            onChange={handelChanege}
            errors={errors}
            text="السعر الافتتاحي بالريال"
          />
          <Input
            type="number"
            value={form.bid_increment}
            name={"bid_increment"}
            onChange={handelChanege}
            errors={errors}
            text=" قيمة المزايدة بالريال"
          />
          <Input
            type="number"
            value={form.insurance_amount}
            name={"insurance_amount"}
            onChange={handelChanege}
            errors={errors}
            text="مبلغ التأمين / رسوم الدفع"
          />
          <Input
            type="number"
            value={form.reserve_price}
            name={"reserve_price"}
            onChange={handelChanege}
            errors={errors}
            text="اقصي سعر / الذي يتم انهاء المزاد عنده"
          />
          <section className=" min-h-[300px] col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تفاصيل المزاد
            </h1>
            <ReactQuill
              value={details}
              onChange={handelChanegeDetails}
              className="text-editor  h-[200px]  rtl"
              style={{ direction: "rtl", textAlign: "right" }}
            />
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              ملفات التعريفية
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {mappedFiles.map((e, inx) => (
                <>
                  <FileUploded
                    key={inx}
                    da={e.url}
                    del={() => {
                      setForm({
                        ...form,
                        deleted_file_ids: [...form.deleted_file_ids, e.id],
                      });
                    }}
                  />
                </>
              ))}
              {form.auction_files.map((e, inx) => (
                <>
                  <FileUploded
                    key={inx}
                    da={e}
                    del={() => {
                      let da = form.auction_files;
                      da = da.filter((i, index) => inx !== index);
                      setForm({
                        ...form,
                        auction_files: da,
                      });
                    }}
                  />
                </>
              ))}

              <label className="btn2" htmlFor="files">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع ملف</h1>
                </section>
              </label>
              <input
                type="file"
                id="files"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      auction_files: [...form.auction_files, e.target.files[0]],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              صور التفاصيل
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {mappedImgs.map((i) => (
                <section className=" py-2 " key={i.id}>
                  <button
                    onClick={() => {
                      setForm({
                        ...form,
                        deleted_images_ids: [...form.deleted_images_ids, i.id],
                      });
                    }}
                    type="button"
                    className=" text-red-600 my-3 "
                  >
                    X{" "}
                  </button>
                  <img
                    src={i.url}
                    className=" h-[100px] rounded-[10px] "
                    alt="details"
                  />
                </section>
              ))}
            </section>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.auction_images.map((e, inx) => (
                <>
                  <FileUploded
                    key={inx}
                    da={e}
                    del={() => {
                      let da = form.auction_images;
                      da = da.filter((i, index) => inx !== index);
                      setForm({
                        ...form,
                        auction_images: da,
                      });
                    }}
                  />
                </>
              ))}

              {!img && (
                <label className="btn2" htmlFor="img">
                  <section className="">
                    <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                    <h1 className="text-[#f2f2f2]">رفع ملف</h1>
                  </section>
                </label>
              )}
              <input
                type="file"
                id="img"
                onChange={handleFileChangeImg}
                style={{ display: "none" }}
              />
            </section>
            {img && (
              <ImageCropper
                imageSrc={img}
                onCropComplete={onImgComplete}
                w={1000}
                h={450}
              />
            )}
          </section>
        </form>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handelAdd} className="btn9">
            تعديل
          </button>

          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {Object.keys(errors).length !== 0 && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            بعض البيانات الاساسية ناقصة
          </section>
        )}
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response ? (
              <>
                {!!error.response.data ? (
                  <>
                    {!!error.response.data.error ? (
                      <>
                        {error.response.data.error ===
                        "ليس لديك اشتراك نشط." ? (
                          <section className=" flex items-center gap-2 flex-wrap ">
                            {error.response.data.error}
                            <Link
                              className=" underline text-[15px] p-2  font-bold "
                              to="/packages"
                            >
                              {" "}
                              عرض الباقات
                            </Link>
                          </section>
                        ) : (
                          error.response.data.error
                        )}
                      </>
                    ) : (
                      "  هناك مشكلة"
                    )}
                  </>
                ) : (
                  "  هناك مشكلة"
                )}
              </>
            ) : (
              "  هناك مشكلة"
            )}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
