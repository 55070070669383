import React, {  useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Tab from "../../../components/pages/Tab";
import useFetchGlobal from "../../../utils/useGetGlobal";
import Details from "./Details";
import Joiners from "./Joiners";
import Error from "../../../components/Error/Error";
import Info from "./Info";
import useFetchGlobalReload from "../../../utils/useGetGlobalReload";

export default function AuctionDetails() {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("auction");
  //   const role = new URLSearchParams(search).get("role");
  const [reload, setReload] = useState(false);
  const re = () => {
    setReload(!reload);
  };
  setTimeout(() => {
    re();
  }, [10000]);
  const { data, error } = useFetchGlobal(`global/auctions/show/${invest}`);
  const { data: bidders } = useFetchGlobalReload(
    `global/auctions/${invest}/bidders`,
    reload
  );

  const [tab, setTab] = useState(1);
  const setTabHandeller = (val) => {
    setTab(val);
  };

  if (error) {
    return <Error />;
  }
  return (
    <div className=" p-4 md:px-8  ">
      <div className=" flex items-center justify-between  ">
        <section className="   text-[16px] font-[600] ">
          <section className="flex items-center gap-1 my-1 ">
            <Link className="text-[#432315BF] " to="/auctions">
              المزاد
            </Link>
            <h1 className="text-[#432315] ">{`> تفاصيل المزاد `}</h1>
          </section>
          <hr className=" w-24 h-[3px] bg-[black] border-black " />
        </section>
      </div>
      <div className=" my-4  md:flex grid-cols-12 items-start justify-center  gap-2  ">
        {/* details */}
        <div className=" col-span-9  flex-1   w-deta  ">
          <section className="   flex items-center justify-between ">
            <section className="   flex items-center gap-3 ">
              <Tab
                text={"التفاصيل"}
                val={1}
                tab={tab}
                setTabState={setTabHandeller}
              />
              <Tab
                text={"المزايدين"}
                val={2}
                tab={tab}
                setTabState={setTabHandeller}
              />
            </section>
          </section>
          {!!data && (
            <>
              <section>{tab === 1 && <Details data={data.data} />}</section>
              {bidders && (
                <section>
                  {tab === 2 && <Joiners data={bidders.data} />}
                </section>
              )}
            </>
          )}
        </div>
        {/* Owner */}
        <section className="   col-span-3 ">
          {!!data && bidders && (
            <Info re={re} max={bidders.data} bid={bidders} data={data.data} />
          )}
        </section>
      </div>
    </div>
  );
}
