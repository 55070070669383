import React, { useEffect } from "react";
import usePostWithToken from "../../../utils/usePostJson";

export default function JoinAuctions({ role, invest, status }) {
  const { postData, response, error } = usePostWithToken(
    `global/auctions/subscribe/${invest}`
  );
  const handelJoin = () => {
    const data = {
      callback_url: `${process.env.REACT_APP_CAll_BACK}?redirect=auction-details&type=auction&value=${invest}&role=${role}`,
    };
    postData(data);
  };

  // console.log(error);
  useEffect(() => {
    if (response) {
      window.location.href = response.redirect_url;
    }
  }, [response]);
  return (
    <div>
      {status === "upcoming" && (
        <button onClick={handelJoin} className="btn2">
          انضم للمزاد {}
        </button>
      )}
      {error && (
        <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
          {/* <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
            <i className="fa-solid fa-exclamation  "></i>
          </span> */}
          {error.response.data.message ? (
            error.response.data.message
          ) : (
            <>
              {error.response.data.error
                ? error.response.data.error
                : "هناك مشكلة"}
            </>
          )}
        </section>
      )}
    </div>
  );
}
