import { useState, useEffect } from "react";
import axios from "axios";
// import { load } from "./isLoadingStore";
import Cookies from "js-cookie";

const useFetchGlobalReload = (endPoint, reload) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  // const { setIsLoading } = useContext(load);

  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      setError(null);
      let token = Cookies.get("token");
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URL}/api/${endPoint}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(res.data);
      } catch (err) {
        setError(err);
      } finally {
        // setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endPoint, reload]);

  return { data, error };
};

export default useFetchGlobalReload;
