import React, { useContext, useEffect, useState } from "react";
import { isLogged } from "../../../../utils/inStore";
import { useLocation, useNavigate } from "react-router";
import Error from "../../../../components/Error/Error";
import Tab from "../../../../components/pages/Tab";
import Mails from "./Mails";
import Social from "./Social";
import Policies from "./Policies";
import Vision from "./Vision";
import SendNotification from "./SendNotification";
import Owner from "./Owner";
import HomePageContent from "./HomePageContetnt";
import CamelOwners from "./CamelOwners";
import RelatedPlatforms from "./RelatedPlatforms";
import Innovations from "./Innovations";

export default function ContactSuperDash() {
  const { logged, role } = useContext(isLogged);

  const navigation = useNavigate();
  useEffect(() => {
    if (!logged) {
      navigation("/login");
      // return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type") || 1;
  const [tab, setTab] = useState(+type);
  const tabs = [
    "بريد التواصل",
    "ارسال الاشعارات",
    "روابط التواصل",
    "السياسات ",
    "الرؤية",
    "معلومات الصفحة الرئيسية",
    "مالك المنصة",
    "ملاك الابل",
    "منصات ذات صلة",
    "الابتكارات"
  ];

  if (role !== "super_admin") {
    return <Error />;
  }

  return (
    <div className=" py-12 px-6 md:p-12 ">
      <h1 className=" text-[36px] font-[800] text-[#432315] ">ادارة التواصل</h1>
      {/* Tabs */}
      <section className=" my-2 flex items-center justify-start gap-1 md:gap-2 overflow-auto w-full ">
        {tabs.map((e, inx) => (
          <Tab
            val={inx + 1}
            key={inx}
            text={e}
            tab={tab}
            setTabState={setTab}
          />
        ))}
      </section>
      <div>{tab === 1 && <Mails />}</div>
      <div>{tab === 2 && <SendNotification />}</div>
      <div>{tab === 3 && <Social />}</div>
      <div>{tab === 4 && <Policies />}</div>
      <div>{tab === 5 && <Vision />}</div>
      <div>{tab === 6 && <HomePageContent />}</div>
      <div>{tab === 7 && <Owner />}</div>
      <div>{tab === 8 && <CamelOwners />}</div>
      <div>{tab === 9 && <RelatedPlatforms />}</div>
      <div>{tab === 10 && <Innovations />}</div>
    </div>
  );
}
