import React from "react";
import Hr from "../../components/pages/Hr";
import { Link } from "react-router-dom";

export default function Ideas() {
  return (
    <div className=" p-4 md:p-16 text-center  ">
      <h1 className=" text-[#432315] text-[36px] font-[600] my-2 text-center ">
        شارك ابتكارك
      </h1>
      <Hr color="#432315" />
      <h4 className=" my-8 leading-[40px]  text-[18px] text-[#A59382] text-center max-w-[600px] mx-auto font-[600] ">
        نستقبل الإبتكارات في قطاع الإبل وما يرتبط به.
        <br />
        لديك فكرة مبتكرة تواصل معنا لندرسها مع مستشارين وفي كامل السرية.
        <br />
        توجد أفكار تحولت بالتعاون إلى مشاريع
      </h4>
      <Link to='/send-innovation' className=" btn ">قم بارسال ابتكارك</Link>
    </div>
  );
}
