import React, { useEffect, useState } from "react";
import useFetchGlobalReload from "../../../../utils/useGetGlobalReload";
import usePostWithToken from "../../../../utils/usePostJson";
import Suc from "./Suc";
import Export from "./Export";

export default function Attends({ back, id, closed, re: mainRe, setClosed }) {
  const [reload, setReload] = useState(false);
  const re = () => {
    setReload(!reload);
  };
  setTimeout(() => {
    re();
  }, [10000]);
  const { data: bidders } = useFetchGlobalReload(
    `global/auctions/${id}/bidders`,
    reload
  );

  const { postData, response } = usePostWithToken(
    `global/auctions/owner/${id}/close`
  );
  const handelJoin = () => {
    postData({
      reserve_price_reached: true,
    });
  };

  useEffect(() => {
    if (response) {
      setSuc(true);
      setClosed("ended");
      mainRe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const [suc, setSuc] = useState(false);
  return (
    <div>
      <section className=" flex items-center gap-2 justify-between flex-wrap ">
        <button onClick={() => back()} className=" text-[18px] ">
          <i className="fa-solid fa-angle-right ml-2 "></i>الرجوع
        </button>
        {closed === "ongoing" && (
          <button onClick={handelJoin} className=" btn ">
            غلق المزاد الان
          </button>
        )}
      </section>
      {bidders && <Export data={bidders.data}  />}
      <>
        <div className=" p-2 md:p-6 ">
          <table>
            <thead>
              <tr>
                <th>الترتيب</th>
                <th>الاسم</th>
                <th>قيمة المزايدة</th>
                <th>الوقت / التاريخ</th>
              </tr>
            </thead>
            {bidders && (
              <tbody>
                {bidders.data.map((i, inx) => (
                  <tr key={inx}>
                    <td className=" text-center ">{i.rank}</td>
                    <td className=" text-center ">{i.name}</td>
                    <td className=" text-center ">{i.amount} ر.س</td>
                    <td className=" text-center ">{i.bid_time}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </>
      {suc && <Suc close={() => setSuc(false)} />}
    </div>
  );
}
