import React from "react";

export default function FileUploded({ da, del }) {
  return (
    <div>
      <div className="w-[200px] bg-[#EEE0C3] p-3 flex items-center justify-between">
        <section className="flex items-center w-[160px] overflow-hidden gap-2 text-[#333333]">
          <i className="fa-regular fa-file text-3xl"></i>
          <h1 className="text-[16px] font-[600]">
            {/* {da.split("/").pop()} */}
            {da.name ? da.name : da.split("/").pop()}
          </h1>
        </section>
        <sec onClick={del}>
          <i className="fa-solid fa-trash text-red-600"></i>
          {/* <i className="fa-solid fa-download text-[#333333] text-[18px]"></i> */}
        </sec>
      </div>
    </div>
  );
}
