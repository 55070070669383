import React from "react";
import ReactDOM from "react-dom";

export default function Suc({ close }) {
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      <section className="max-h-[95vh] w-full max-w-[600px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <i className="fa-solid fa-check text- mx-auto text-7xl my-2 text-green-600"></i>
        <h1 className=" text-center  text-3xl my-2 text-green-600 font-bold ">
          تم غلق المزاد بنجاح
        </h1>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={() => close()} className="btn8">
            اغلاق
          </button>
        </section>
      </section>
    </div>,
    document.body
  );
}
