import React from "react";

export default function RelatedComponent({ data }) {
  return (
    <div className=" bg-[#E8DCC0] text-[19px] font-[600] text-[#290900] leading-[37px] flex items-center gap-3 p-[16px] rounded-[8px] w-full max-w-[320px] ">
      <img
        src={data.logo}
        alt={data.name}
        className=" max-h-[60px] max-w-[100px] "
      />
      <a href={data.link} target="_blank" rel="noreferrer">
        <h1>{data.name}</h1>
      </a>
    </div>
  );
}
