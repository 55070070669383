import React, { useEffect, useState } from "react";
import Banner from "./Banner";
import Sponsers from "./Sponsers";
import Vision from "./Vision";
import Servs from "./Servs";
import Events from "./Events";
import Owners from "./Owners";
import News from "./News";
import Gallery from "./Gallery";
import Camels from "./Camels";
import Owner from "./Owner";
import "./style.css";
import { Helmet } from "react-helmet-async";
import useFetchWithReload from "../../utils/useGetWithReload";
import RelatedSection from "./RelatedSites/RelatedSection";
import Ideas from "./Ideas";

export default function Home() {
  let link = "global/vision";
  const { data } = useFetchWithReload(link);
  const [vision, setVision] = useState("");
  const [about, setAbout] = useState("");
  const [services, setServices] = useState([]);
  useEffect(() => {
    if (data) {
      setVision(data.vision.description);
      let fil = data.home_page_contents.filter((i) => i.key === "services");
      if (fil.length > 0) {
        setAbout(fil[0].content);
        setServices(fil[0].services);
      }
    }
  }, [data]);
  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "منصة الابل",
        "description": "منصة متخصصة في كل ما يخص الابل وسباقات الابل والجمال والمنتجات المتعلقة بها والفعليات التي ينظمها المهتمون بها والطب البيطري المتخصص للابل من مستشفيات و متخصصون",
        "url": "https://mitracamel.com"
      }
    `}
        </script>
      </Helmet>
      <Banner about={about} />
      <Sponsers />
      <Vision vision={vision} />
      <Servs services={services} />
      <Owner />
      <Ideas />
      <Events />
      <Owners />
      <Camels />
      <News />
      <RelatedSection />
      <Gallery />
    </div>
  );
}
