import React from "react";
import MyJoinAuctions from "../dashboard/pages/MyJoins/Auctions/MyJoinAuctions";

export default function JoinsAuctions() {
  return (
    <div className=" py-12 px-6 md:p-12 ">
      <h1 className=" text-[36px] font-[800] text-center text-[#432315] ">
        مشاراكاتي في المزادات
      </h1>
      <div className=" my-6 ">
        <MyJoinAuctions />
      </div>
    </div>
  );
}
