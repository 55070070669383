import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import File from "../../../components/pages/File";

export default function Details({ data }) {
  return (
    <div>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        autoplay={{
          delay: 4000, // Time delay in milliseconds
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[Pagination, Autoplay]}
        className="mySwiper "
      >
        {data.media.auction_images.map((e, index) => (
          <SwiperSlide className="  " key={index}>
            <div className="   relative bg-origin-content bg-cover  w-full   max-h-430px]">
              <img
                className={` rounded-[16px] transition-all ease-in-out duration-[3000ms]  left-0 top-0 h-full w-full object-cover `}
                key={e}
                src={`${e.url}`}
                alt="cover"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <h1 className=" text-[33px] font-[900] my-4 ">الوصف</h1>
      <div
        className="styled-content rtl leading-loose relative py-4  "
        dangerouslySetInnerHTML={{ __html: data.details }}
        style={{ direction: "rtl", textAlign: "right" }}
      />
      <h1 className=" my-10 text-[24px] text-[#432315] font-[800] ">
        ملفات تعريفية للمزاد
      </h1>
      <section className=" mb-6 flex gap-2 flex-wrap ">
        {data.media.auction_files.map((e, inx) => (
          <File key={inx} da={`${e.url}`} />
        ))}
      </section>
    </div>
  );
}
