import { Menu } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import font from "../../../../utils/Amiri-Regular-normal";
import jsPDF from "jspdf";
import "jspdf-autotable";

const statues = {
  ongoing: "جاري",
  ended: "منتهي",
  upcoming: "قادي",
};

const generatePDF = (data, type) => {
  const doc = new jsPDF();

  doc.addFileToVFS("Amiri-Regular.ttf", font);
  doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
  doc.setFont("Amiri");
  doc.setFontSize(12);

  const tableColumn = [
    "عدد المشاركين",
    "اعلي عرض",
    "سعر التأمين",
    "السعر الافتتاحي",
    "حالة المزاد",
    "المدينة",
    "اسم المزاد",
  ];
  const tableRows = [];
  data.forEach((i) => {
    const tableData = [
      i.bidders_count,
      i.highest_bid,
      i.insurance_amount,
      i.starting_price,
      statues[i.status],
      i.city,
      i.camel_name,
    ];
    tableRows.push(tableData);
  });

  doc.text(`تقارير المزادات`, 190, 15, { align: "right" });

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 25,
    styles: {
      font: "Amiri",
      fontSize: 12,
    },
    bodyStyles: { valign: "middle", halign: "right" },
    headStyles: { fillColor: [63, 81, 181], halign: "right" },
    tableLineWidth: 0.1,
    margin: { left: 10, right: 10 },
  });
  doc.save(`تقارير المزادات.pdf`);
};

export default function ExportReports({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const headers = [
    { label: "اسم المزاد", key: "camel_name" },
    { label: "المدينة", key: "city" },
    { label: "حالة المزاد", key: "status" },
    { label: "السعر الافتتاحي", key: "starting_price" },
    { label: "سعر التأمين", key: "insurance_amount" },
    { label: "اعلي عرض", key: "highest_bid" },
    { label: "عدد المشاركين", key: "bidders_count" },
  ];

  const [body, setBody] = useState([]);
  useEffect(() => {
    if (data) {
      let co = [];
      // eslint-disable-next-line array-callback-return

      data.forEach((i) => {
        co.push({
          camel_name: i.camel_name,
          city: i.city,
          status: statues[i.status],
          starting_price: i.starting_price,
          insurance_amount: i.insurance_amount,
          highest_bid: i.highest_bid,
          bidders_count: i.bidders_count,
        });
      });

      setBody(co);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div>
      <section className=" my-2 flex items-center justify-end ">
        <button
          onClick={handleClick}
          className=" btn2 flex items-center justify-center gap-2 "
        >
          تصدير <i class="fa-solid fa-caret-down" />
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <button
            onClick={() => {
              handleClose();
              generatePDF(data);
            }}
            className=" py-2 px-12 border-b flex items-center justify-center gap-2 "
          >
            PDF <i className="fa-solid fa-file-pdf"></i>
          </button>
          <button
            onClick={() => {
              handleClose();
            }}
            className=" "
          >
            <CSVLink
              data={body}
              headers={headers}
              filename={`تقارير المزادات.csv`}
              target="_blank"
              className=" w-full  py-2 px-12 border-b flex items-center justify-center gap-2 "
            >
              CVS <i className="fa-solid fa-table"></i>
            </CSVLink>
          </button>
        </Menu>
      </section>
    </div>
  );
}
