import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Loader from "../../../../components/Loader/Loader";
import { load } from "../../../../utils/isLoadingStore";
import usePostFormData from "../../../../utils/usePostForm";

export default function EditSite({ close, object, set }) {
  const [form, setForm] = useState({
    name: object.name,
    logo: null,
    type: object.type,
    link: object.link,
  });

  const handelChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const { postFormData, response } = usePostFormData(
    `superAdmin/related_sites/update/${object.id}`
  );
  const handelAdd = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("name", form.name);
    if (form.logo) {
      formdata.append("logo", form.logo);
    }
    formdata.append("type", form.type);
    formdata.append("link", form.link);
    postFormData(formdata);
  };
  useEffect(() => {
    if (!!response) {
      set();
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1200px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <section className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8     ">
          {" "}
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اسم المنصة
            </h1>
            <input
              name="name"
              value={form.name}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="  اسم المنصة"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نوع المنصة
            </h1>
            <select
              name="type"
              value={form.type}
              onChange={handelChange}
              className=" inpt "
              required
            >
              <option value={"saudi"}>منصة سعودية</option>
              <option value={"other"}>اخري</option>
            </select>
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              رابط المنصة
            </h1>
            <input
              name="link"
              value={form.link}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="  رابط المنصة"
              required
            />
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تغيير الصورة
            </h1>
            <section className=" flex items-center gap-2 ">
              <label>رفع صورة :</label>
              <input
                value={form.logo ? form.logo.value : ""}
                required
                type="file"
                id="file"
                accept="image/*"
                onChange={(e) => {
                  setForm({ ...form, logo: e.target.files[0] });
                }}
              />
            </section>
          </section>
        </section>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handelAdd} className="btn9">
            تعديل
          </button>
          <button onClick={() => close()} className="btn8">
            الغاء
          </button>
          {/* {error && (
            <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
              <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
                <i className="fa-solid fa-exclamation  "></i>
              </span>
              هناك مشكلة
            </section>
          )} */}
        </section>
      </section>
    </div>,
    document.body
  );
}
