import React, { useState } from "react";
import Suc from "./Suc";
import usePostWithToken from "../../utils/usePostJson";

export default function Form() {
  const [userInfo, setUserInfo] = useState({
    name: "",
    phone: "",
    email: "",
    nationality: "",
    subject: "",
    message: "",
  });

  const { nationality, subject, message, name, phone, email } = userInfo;
  const handleChangeInfo = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };
  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};

    if (!name) {
      formErrors.name = "الرجاء إدخال الاسم";
    }
    const phoneRegex = /^\d{10,15}$/; // Example: Adjust the regex as needed for your phone validation.
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate phone
    if (!phone) {
      formErrors.phone = "الرجاء إدخال رقم الهاتف";
    } else if (!phoneRegex.test(phone)) {
      formErrors.phone = "الرجاء إدخال رقم هاتف صحيح";
    }

    // Validate email
    if (!email) {
      formErrors.email = "الرجاء إدخال البريد الإلكتروني";
    } else if (!emailRegex.test(email)) {
      formErrors.email = "الرجاء إدخال بريد إلكتروني صحيح";
    }

    if (!nationality) {
      formErrors.nationality = "الرجاء إدخال الجنسية";
    }
    if (!subject) {
      formErrors.subject = "الرجاء إدخال عنوان الرسالة";
    }
    if (!message) {
      formErrors.message = "الرجاء إدخال موضوع الرسالة";
    }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };
  const { postData, response, error } = usePostWithToken(`global/contact_us`);

  const sendHandeller = (e) => {
    e.preventDefault();
    if (validate()) {
      postData(userInfo);
    }
  };

  return (
    <div>
      {!response ? (
        <div className=" mb-3 bg-[#E8DCC0] rounded-[15px] p-4 ">
          <form
            className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16  flex-wrap  "
            onSubmit={sendHandeller}
          >
            <section className=" col-span-1  ">
              <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
                الاسم
              </h1>
              <input
                name="name"
                value={name}
                onChange={handleChangeInfo}
                className=" inpt "
                type="text"
                placeholder="الاسم"
                // required
              />
              {<p className="error">{errors.name}</p>}
            </section>
            <section className=" col-span-1  ">
              <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
                البريد الإلكتروني
              </h1>
              <input
                name="email"
                value={email}
                onChange={handleChangeInfo}
                className=" inpt "
                type="email"
                placeholder="   البريد الإلكتروني"
                // required
              />
              {<p className="error">{errors.email}</p>}
            </section>
            <section className=" col-span-1  ">
              <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
                رقم الهاتف
              </h1>
              <input
                name="phone"
                value={phone}
                onChange={handleChangeInfo}
                className=" inpt "
                placeholder="      رقم الهاتف"
                // required
              />
              {<p className="error">{errors.phone}</p>}
            </section>
            <section className=" col-span-1 ">
              <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
                الجنسية
              </h1>
              {/* <input
                name="nationality"
                value={nationality}
                onChange={handleChangeInfo}
                className=" inpt "
                type="text"
                placeholder="الجنسية"
                // required
              /> */}
              <select
                name="nationality"
                value={nationality}
                onChange={handleChangeInfo}
                className=" inpt "
                type="text"
                placeholder="الجنسية"
                // required
              >
                <option hidden>الجنسية</option>
                <option value={"السعودية"}>السعودية</option>
                <option value={"الامارات"}>الامارات</option>
                <option value={"البحرين"}>البحرين</option>
                <option value={"قطر"}>قطر</option>
                <option value={"مصر"}>مصر</option>
                <option value={"اخري"}>اخري</option>
              </select>
              {<p className="error">{errors.nationality}</p>}
            </section>
            <section className=" col-span-1 lg:col-span-2  ">
              <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
                عنوان الرسالة
              </h1>
              <input
                name="subject"
                value={subject}
                onChange={handleChangeInfo}
                className=" inpt "
                type="text"
                placeholder="عنوان الرسالة"
                // required
              />
              {<p className="error">{errors.subject}</p>}
            </section>
            <section className=" col-span-1 lg:col-span-2 ">
              <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
                الموضوع
              </h1>
              <textarea
                rows={5}
                name="message"
                value={message}
                onChange={handleChangeInfo}
                className=" inpt "
                type="text"
                placeholder=" الموضوع"
                // required
              />
              {<p className="error">{errors.message}</p>}
            </section>
            <section className=" col-span-1 lg:col-span-2  ">
              <button type="submit" className=" btn2 ">
                أرسال
              </button>
            </section>
            {error && (
              <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
                <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
                  <i className="fa-solid fa-exclamation  "></i>
                </span>
                {error.response.data.message
                  ? error.response.data.message
                  : "هناك مشكلة"}
              </section>
            )}
          </form>
        </div>
      ) : (
        <Suc />
      )}
    </div>
  );
}
