import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import Notegister from "../../../components/Notegister";
import { isLogged } from "../../../utils/inStore";
import CopyBtn from "../../../components/pages/CopyBtn";
import JoinAuctions from "../Join/JoinAuctions";
import useFetchWithToken from "../../../utils/useGetWithToken";
import Bid from "./Bid";

const Time = ({ val, name }) => (
  <section className=" h-[55px] w-[60px] rounded-[8px] border-[#432315] border border-b-[3px] ">
    <h1 className=" text-center text-[21px] font-[700] leading-[18px] mt-2  ">
      {val}
    </h1>
    <h4 className=" text-center text-[14px] font-[400] leading-[18px] ">
      {name}
    </h4>
  </section>
);
export default function Info({ data, max, re, bid }) {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("auction");
  const role = new URLSearchParams(search).get("role");

  const { data: myLocs } = useFetchWithToken(
    "global/auctions/my_joined_auctions"
  );
  const [sub, setSub] = useState(false);
  useEffect(() => {
    if (myLocs && myLocs.auctions) {
      const auctionExists = myLocs.auctions.some(
        (auction) => Number(auction.auction_id) === Number(invest)
      );
      if (auctionExists) {
        setSub(true);
      }
    }
  }, [myLocs, invest]);

  const { logged } = useContext(isLogged);
  const [open, setOpen] = useState(false);
  const toggelOpenRegisterMessage = () => {
    setOpen(!open);
  };

  const targetDate = new Date(data.end_time).getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference <= 0 || data.status === "ended") {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className=" w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
        <h1 className=" text-[#290900] text-center font-[600] text-[18px] leading-[38px] ">
          {max.length > 0 ? "  اعلى سعر " : "السعر الافتتاحي"}
        </h1>
        <h1 className=" text-[#290900] font-[800] text-[23px] text-center leading-[28px] ">
          {max.length > 0 ? bid.highest_bid : data.starting_price} ر.س
        </h1>
      </div>
      <div className=" my-4 w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
        {(sub || data.status === "upcoming") && (
          <h1 className=" text-[#290900] text-center font-[600] text-[18px] leading-[38px] ">
            ادخل صفقتك
          </h1>
        )}
        {!sub && data.status === "upcoming" && (
          <p className=" text-[#A59382] text-center font-[600] text-[14px] leading-[22px] ">
            المبلغ المحدد يشير للصفقة، وسيتم دفع الرسوم فقط الآن قيمته (
            {data.insurance_amount}
            ر.س) كإثبات جدية وضمان لاستمرار العملية مع استرجاع أموالك في حالة
            الخسارة
          </p>
        )}
        <section className=" my-4 ">
          {logged ? (
            <section>
              <section className=" text-center ">
                {data.status === "ended" ? (
                  <></>
                ) : (
                  <>
                    {/* <Link to={`/join-auction?auction=${invest}&role=${role}`}>
                      <button className="btn2">انضم للمزاد</button>
                    </Link> */}
                    {myLocs && (
                      <>
                        {sub ? (
                          <Bid
                            max={max}
                            re={re}
                            invest={invest}
                            bid={data.bid_increment}
                            start={data.starting_price}
                            status={data.status}
                          />
                        ) : (
                          <JoinAuctions
                            status={data.status}
                            invest={invest}
                            role={role}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </section>
            </section>
          ) : (
            <section className="text-center">
              <button onClick={toggelOpenRegisterMessage} className="btn2">
                انضم للمزاد
              </button>
            </section>
          )}
        </section>
        <section className=" my-8 ">
          <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
           متبقي من الوقت
          </h1>
          <section className=" my-4 flex items-center justify-center gap-4 ">
            <Time val={timeLeft.days} name={"يوم"} />
            <Time val={timeLeft.hours} name={"ساعة"} />
            <Time val={timeLeft.minutes} name={"دقيقة"} />
            <Time val={timeLeft.seconds} name={"ثواني"} />
          </section>
        </section>
        <section className=" my-8 ">
          <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
            السعر الافتتاحي
          </h1>
          <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
            {data.starting_price} ر.س
          </h1>
        </section>
        <section className=" my-8 ">
          <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
            مبلغ التأمين
          </h1>
          <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
            {data.insurance_amount} ر.س
          </h1>
        </section>
        <section className=" my-8 ">
          <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
            تاريخ المزاد
          </h1>
          <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
            {data.start_time.substring(0, 10)}
          </h1>
        </section>
        <section className=" my-8 ">
          <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
            عدد المزايدين
          </h1>
          <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
            {data.bidders_count}
          </h1>
        </section>
        <section className=" my-8 ">
          <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
            العنوان
          </h1>
          <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
            {data.city !== "اخرى" && `${data.city}  , `} {data.address}
          </h1>
        </section>
        {data.map_link && (
          <section className=" my-8 ">
            <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
              فتح العنوان من الخريطة
            </h1>
            <a
              href={data.map_link}
              target="_blank"
              rel="noreferrer"
              className=" text-[19px] font-[600] text-sky-800 underline  "
            >
              الذهاب الي العنوان
            </a>
          </section>
        )}
        <CopyBtn />
      </div>

      {open && (
        <Notegister
          close={toggelOpenRegisterMessage}
          msg=" الرجاء التسجيل للانضمام للمزاد"
        />
      )}
    </>
  );
}
