import React, { useContext } from "react";
import { isLogged } from "../../../../utils/inStore";
import { useLocation } from "react-router";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import Error from "../../../../components/Error/Error";
import { Link } from "react-router-dom";

export default function ShowInnovation() {
  const { logged, role } = useContext(isLogged);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("innovation");
  let link = `superAdmin/innovation/show/${id}`;
  const { data, error } = useFetchWithReload(link);

  if (role !== "super_admin" || !logged || error) {
    return <Error />;
  }
  return (
    <div>
      {data && (
        <div className="  p-8 ">
          <Link
            className=" absolute top-4 right-4 underline "
            to="/contact-dash?type=10"
          >
            الرجوع للابتكارات
          </Link>
          <h1 className=" text-[#432316] my-8 text-2xl font-bold text-center ">
            تفاصيل الابتكار
          </h1>
          <section className=" bg-[#E8DCC0] rounded-[15px] p-4 mx-auto max-w-[1100px] grid grid-cols-1 lg:grid-cols-2 gap-8 md:px-12 lg:px-32  ">
            <section>
              <h4>المرسل :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.data.name}
              </h1>
            </section>
            <section>
              <h4>الجنسية :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.data.nationality}
              </h1>
            </section>
            <section>
              <h4>البريد الاكتروني :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.data.email}
              </h1>
            </section>
            <section>
              <h4> رقم الهاتف :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.data.phone}
              </h1>
            </section>
            <section className=" lg:col-span-2">
              <h1 className=" text-[#432316] my-8 text-2xl font-bold text-center ">
                تفاصيل الابتكار
              </h1>
            </section>
            <section>
              <h4>عنوان الابتكار :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.data.innovation_title}
              </h1>
            </section>
            <section>
              <h4>الفئة المستهدفة :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.data.target_category}
              </h1>
            </section>
            <section>
              <h4>هل الابتكار تم تطبقية ؟ :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.data.is_applied ? "نعم" : "لا"}{" "}
                {data.data.is_applied && (
                  <span className=" text-[20px] ">{`, الرقم التسجيلي : ${data.data.registration_number}`}</span>
                )}
              </h1>
            </section>
            <section className=" lg:col-span-2">
              <h4>وصف الابتكار :</h4>
              <h1 className=" text-[#432316] my-2 text-2xl font-bold ">
                {data.data.description}
              </h1>
            </section>
            <section className=" flex items-start justify-center gap-4 flex-wrap lg:col-span-2">
              {data.data.media.innovation_files.map((i) => (
                <a key={i.id} target="_blank" href={i.url} rel="noreferrer">
                  <img
                    className="  h-[200px] rounded-[4px]  "
                    src={i.url}
                    alt={i.id}
                  />
                </a>
              ))}
            </section>
          </section>
        </div>
      )}
    </div>
  );
}
