import React from "react";
// import Hr from "../../components/pages/Hr";

export default function Vision({ vision }) {
  return (
    <div className=" py-28 px-8 md:px-16 relative ">
      <div className=" absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full">
        <img
          loading="lazy"
          src="./imgs/vision2.png"
          // src="./imgs/home/vision.png"
          alt="Vision"
          className="absolute left-0 top-0 h-full w-full object-cover "
        />
      </div>
      {/* <div className=" w-full  max-w-[540px] py-[30px] px-[15px] bg-[#6B65618F]/50 text-center rounded-[15px] xl:mr-[6rem] 2xl:mr-[15rem] relative z-10">
        <h1 className=" my-2 text-[#E8DCC0] text-[32px] md:text-[48px] font-[700] ">
          الرؤية
        </h1>
        <Hr color="#E8DCC0" />

        <p className=" my-2 text-[#E8DCC0]/60 leading-[40px] text-[18px] font-[600] ">
          {vision}
        </p>
      </div> */}
      <div className=" w-full  max-w-[560px] flex items-center gap-4  px-[15px] xl:mr-[6rem] 2xl:mr-[15rem] relative z-10">
        <img
          src="./imgs/navLogoCropped.png"
          alt="Vision"
          className="w-[132px] hidden md:flex "
        />
        <section className=" p-3 md:border-r-[3px] border-[#432315] ">
          <h1 className=" text-[45px] font-[500] text-[#432315] ">الرؤية</h1>
          {vision && (
            <p className=" text-[#432315] mt-4 text-[30px] font-[600] ">
              {vision}
            </p>
          )}
        </section>
      </div>
    </div>
  );
}
