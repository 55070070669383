import { Menu } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import font from "../../../../utils/Amiri-Regular-normal";

const generatePDF = (data) => {
  const doc = new jsPDF();

  doc.addFileToVFS("Amiri-Regular.ttf", font);
  doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
  doc.setFont("Amiri");
  doc.setFontSize(12);

  const tableColumn = ["الوقت / التاريخ", "قيمة المزايدة", "الاسم", "الترتيب"];
  const tableRows = [];

  data.forEach((item, index) => {
    const tableData = [item.bid_time, item.amount, item.name, item.rank];
    tableRows.push(tableData);
  });

  doc.text(`المشتركين في المزاد`, 190, 15, { align: "right" });

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 25,
    styles: {
      font: "Amiri",
      fontSize: 12,
    },
    bodyStyles: { valign: "middle", halign: "right" },
    headStyles: { fillColor: [63, 81, 181], halign: "right" },
    tableLineWidth: 0.1,
    margin: { left: 10, right: 10 },
  });
  doc.save(`المشتركين في المزاد.pdf`);
};
export default function Export({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const headers = [
    { label: "الترتيب", key: "rank" },
    { label: "الاسم", key: "name" },
    { label: "قيمة المزايدة", key: "amount" },
    { label: "الوقت / التاريخ", key: "bid_time" },
  ];

  const [body, setBody] = useState([]);
  useEffect(() => {
    if (data) {
      let co = [];
      // eslint-disable-next-line array-callback-return
      data.map((i) => {
        co.push({
          rank: i.rank,
          name: i.name,
          amount: i.amount,
          bid_time: i.bid_time,
        });
      });
      setBody(co);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div>
      {" "}
      <section className=" my-2 flex items-center justify-end ">
        <button
          onClick={handleClick}
          className=" btn2 flex items-center justify-center gap-2 "
        >
          تصدير <i class="fa-solid fa-caret-down" />
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <button
            onClick={() => {
              handleClose();
              generatePDF(data);
            }}
            className=" py-2 px-12 border-b flex items-center justify-center gap-2 "
          >
            PDF <i className="fa-solid fa-file-pdf"></i>
          </button>
          <button
            onClick={() => {
              handleClose();
            }}
            className=" "
          >
            <CSVLink
              data={body}
              headers={headers}
              filename={`المشتركين في المزاد.csv`}
              target="_blank"
              className=" w-full  py-2 px-12 border-b flex items-center justify-center gap-2 "
            >
              CVS <i className="fa-solid fa-table"></i>
            </CSVLink>
          </button>
        </Menu>
      </section>
    </div>
  );
}
